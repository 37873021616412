import classNames from 'classnames'
import { navigate } from 'gatsby'
import React from 'react'
import { CircleProgress } from 'react-gradient-progress'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import paths from 'constants/paths'
import statusCodes from 'constants/status-codes'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as userApiRequest from 'libs/user-api-request'
import constants from 'styles/constants.module.scss'
import styles from './OnboardingEnterpriseReefCheckLoader.module.scss'

export default function OnboardingEnterpriseReefCheckLoader() {
  const globalContext = useGlobalContext()
  const [isEligible, setIsEligible] = React.useState(false)
  const [isEligibilityCheckDone, setIsEligibilityCheckDone] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    if (!globalContext.user) {
      setIsEligibilityCheckDone(true)
      return
    }

    async function checkEligibility() {
      // pverify first, and still checks partner if pverify fails
      const response = await userApiRequest.checkUserHealthPlanAndConvert({
        dob: globalContext.user.dateOfBirth,
        firstName: globalContext.user.firstName,
        lastName: globalContext.user.lastName,
      })

      const updatedUser = response.data

      if (response.statusCode === statusCodes.SUCCESS && updatedUser) {
        await globalContext.update({ user: updatedUser })
        await globalContext.updateUser()
        setIsEligibilityCheckDone(true)
        setIsEligible(true)
      }

      setIsEligibilityCheckDone(true)
    }

    checkEligibility()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 3500) // 3.5 sec
  }, [])

  React.useEffect(() => {
    if (isEligibilityCheckDone && !isLoading) {
      navigate(
        isEligible
          ? paths.ONBOARDING_ENTERPRISE_ELIGIBILITY
          : paths.ONBOARDING_ENTERPRISE_ELIGIBILITY_RECHECK,
        { replace: true }
      )
    }
  }, [isEligible, isEligibilityCheckDone, isLoading])

  return (
    <View className={classNames('OnboardingEnterpriseReefCheckLoader', styles.this)}>
      <div>
        <Row size="smallx">
          <div className={styles.progress}>
            <div className={styles['progress--circle']}>
              <CircleProgress
                percentage={33}
                primaryColor={[constants.PURPLE, constants.PURPLE]}
                secondaryColor="#f5f5f5"
                strokeWidth={14}
                width={138}
              />
            </div>
          </div>
        </Row>
        <Text weight="medium">Checking your eligibility</Text>
      </div>
    </View>
  )
}
