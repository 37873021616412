import classNames from 'classnames'
import React from 'react'
import Field2, { type Field2Props } from 'components/Field2'

type Props = Partial<Field2Props>

export default function FieldGender({ className, ...props }: Props) {
  return (
    <Field2
      label="Gender"
      name="gender"
      options={[
        {
          label: 'Male',
          value: 'male',
        },
        {
          label: 'Female',
          value: 'female',
        },
        {
          label: 'Prefer not to answer',
          value: 'N/A',
        },
      ]}
      type="radio"
      data-test-id="gender-input"
      {...props}
      className={classNames('FieldGender', className)}
    />
  )
}
