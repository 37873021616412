import { useLocation } from '@reach/router'
import React from 'react'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import ProgramFollowUpModal from 'features/program/components/ProgramFollowUpModal'
import * as checkinApiRequest from 'libs/checkin-api-request'
import * as user from 'libs/user'

export default function EnterpriseReefOrcaCheckinModal() {
  const globalContext = useGlobalContext()
  const location = useLocation()
  const [checkinData, setCheckinData] = React.useState(null)

  React.useEffect(() => {
    const onProgramPage =
      location.pathname === paths.PROGRAM ||
      location.pathname === paths.PROGRAM.slice(0, Math.max(0, paths.PROGRAM.length - 1))
    const isReefOrca = user.isEnterpriseReefOrOrcaUser(globalContext?.user)

    if (isReefOrca && !onProgramPage) {
      checkinApiRequest
        .getUserCheckin({
          type: 'program_follow_up',
          activeOnly: true,
        })
        .then((response) => {
          if (response.data) setCheckinData(response.data)
        })
    }
  }, [globalContext.user, location.pathname])

  if (!checkinData) return null

  return (
    <ProgramFollowUpModal
      programFollowUpCheckin={checkinData}
      className="EnterpriseReefOrcaCheckinModal"
      onCheckinSuccess={() => {
        setCheckinData(null)
      }}
    />
  )
}
