import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Divider from 'components/Divider'
import Loader from 'components/Loader'
import type { Component } from 'constants/types'
import type { ProgramDynamic } from 'libs/program'
import { useUserProgramBlocks } from 'libs/program-query'
import ProgramBlock, { type ProgramBlockProps } from '../ProgramBlock'

type Props = {
  lessonOverride: any
  onLessonSkipped: any
  playSkippedLesson: any
  program: ProgramDynamic
} & Partial<ProgramBlockProps> &
  Component

export default function ProgramBlocks({
  className,
  lessonOverride,
  onLessonSkipped,
  playSkippedLesson,
  program,
  ...props
}: Props) {
  const { data: userProgramBlocksResponse } = useUserProgramBlocks(program?.id)
  const userExerciseBlocks = userProgramBlocksResponse?.data
  let currentBlock: string = null
  if (!_.isEmpty(userExerciseBlocks)) {
    for (const block of userExerciseBlocks) {
      if (block.status === 'active') {
        currentBlock = block
        break
      }
    }
  }

  if (program?.id == null) return null

  return (
    <div className={classNames('ProgramBlocks', className)}>
      {_.isEmpty(userExerciseBlocks) ? (
        <Loader overlay={false} />
      ) : (
        userExerciseBlocks.map(
          (
            block: any,
            index: number // TODO Type
          ) => (
            <React.Fragment key={block.id}>
              <ProgramBlock
                {...props}
                data={block}
                currentBlock={currentBlock}
                lessonOverride={lessonOverride}
                onLessonSkipped={onLessonSkipped}
                playSkippedLesson={playSkippedLesson}
              />
              {index < _.size(userExerciseBlocks) - 1 && <Divider axis="y" size="small" />}
            </React.Fragment>
          )
        )
      )}
    </div>
  )
}
