import _ from 'lodash'
import React from 'react'
import Loader from 'components/Loader'
import Row from 'components/Row'
import styles from './AdminLessonRegimensWeek.module.scss'
import AdminLessonRegimensDay from './components/AdminLessonRegimensDay'

type Props = {
  list: []
  onUpdate: () => void
}

export default function AdminLessonRegimensWeek({ list, onUpdate: handleUpdate }: Props) {
  if (_.isNil(list)) return <Loader />
  const dayOfWeekView = {}
  list.forEach((item) => {
    item.liveSchedule.forEach((schedule) => {
      const liveDays = schedule[0]
      liveDays.forEach((liveDay) => {
        if (!dayOfWeekView[liveDay]) {
          dayOfWeekView[liveDay] = [
            {
              ...item,
              liveTimePT: schedule[1],
              type: 'Live',
            },
          ]
        } else {
          dayOfWeekView[liveDay].push({
            ...item,
            liveTimePT: schedule[1],
            type: 'Live',
          })
        }
      })
    })
    item.rerunSchedule.forEach((schedule) => {
      const rerunDays = schedule[0]
      rerunDays.forEach((rerunDay) => {
        if (!dayOfWeekView[rerunDay]) {
          dayOfWeekView[rerunDay] = [
            {
              ...item,
              rerunTimePT: schedule[1],
              type: 'Rerun',
            },
          ]
        } else {
          dayOfWeekView[rerunDay].push({
            ...item,
            rerunTimePT: schedule[1],
            type: 'Rerun',
          })
        }
      })
    })
  })

  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  const daysOfWeekFilled = daysOfWeek.map((day) => {
    const sortedSchedule = _.sortBy(dayOfWeekView[day], (schedule) =>
      schedule.type === 'Live' ? schedule.liveTimePT : schedule.rerunTimePT
    )
    return (
      {
        schedule: sortedSchedule || [],
        day,
      } || []
    )
  })

  return (
    <Row size="small">
      <div className={styles.days}>
        {daysOfWeekFilled.map((dayOfWeek, index) => (
          <AdminLessonRegimensDay
            key={index}
            scheduleForDay={dayOfWeek.schedule}
            dayOfWeek={dayOfWeek.day}
            onUpdate={handleUpdate}
          />
        ))}
      </div>
    </Row>
  )
}
