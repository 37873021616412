import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Button from 'components/Button'
import ModalBox from 'components/ModalBox'
import Text from 'components/Text'
import * as events from 'constants/events'
import urls from 'constants/urls'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as api from 'libs/api'

type Props = {
  onRequestClose: () => void
}

export default function AppDownloadModalBox({ onRequestClose }: Props) {
  const globalContext = useGlobalContext()
  const ipRef = React.useRef()

  React.useEffect(() => {
    async function getIp() {
      const response = await fetch('https://api.ipify.org?format=json')
      const data = await response.json()
      if (data.ip) ipRef.current = data.ip
    }
    getIp()
  }, [globalContext.user, ipRef])

  const registerIpForDownload = async () => {
    if (!ipRef.current) return

    await api.post('registerIpForAppDownloadAuthed', {
      ipAddress: ipRef.current,
    })
  }

  function handleDownloadClick() {
    globalContext.analytics?.trackEvent(events.DOWNLOAD_APP_CLICK, {
      page: 'Program',
    })
    registerIpForDownload()
    window.open(urls.APP_STORE, '_blank')
    onRequestClose()
  }

  return (
    <>
      <StaticImage
        src="./images/phones@2x.jpg"
        alt="Bold app"
        layout="fullWidth"
        placeholder="blurred"
        width={360}
      />
      <ModalBox align="left">
        <Text element="p">Use Bold on the iOS mobile app. Take classes anytime, anywhere.</Text>
        <Button full onClick={handleDownloadClick}>
          Download app
        </Button>
      </ModalBox>
    </>
  )
}
