import classNames from 'classnames'
import React from 'react'
import Field2, { type Field2Props } from 'components/Field2'
import * as validations from 'libs/validations'

// TODO
// input mask

type Props = Field2Props

export default function FieldPostalCode({
  className,
  // destructuring because react-media can pass as a prop that causes a noisey console warning
  matches,
  ...props
}: Props) {
  return (
    <Field2
      autoComplete="postal-code"
      inputMode="numeric"
      label="ZIP code"
      maxLength={validations.POSTAL_CODE_LENGTH_MAX}
      name="postalCode"
      type="tel"
      data-test-id="postal-code-input"
      {...props}
      className={classNames('FieldPostalCode', className)}
    />
  )
}
