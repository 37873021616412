import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Loader from 'components/Loader'
import type { Component } from 'constants/types'
import type { Lesson } from 'libs/lesson'
import styles from './BasicClasses.module.scss'
import ExerciseCard from './components/ExerciseCard'

type Props = {
  data: Lesson[]
  locked?: boolean
} & Component

export default function BasicClasses({ className, data, locked }: Props) {
  if (_.isEmpty(data)) {
    return <Loader overlay={false} />
  }

  return (
    <div
      className={classNames(
        'BasicClasses',
        styles.this,
        locked && styles['this---locked'],
        className
      )}>
      {data.map((lesson) => (
        <div key={lesson.id} className={styles.cardContainer}>
          <ExerciseCard data={lesson} locked={locked} />
        </div>
      ))}
    </div>
  )
}
