import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Container from 'components/Container'
import Head from 'components/Head'
import Text from 'components/Text'
import View from 'components/View'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import {
  OnboardingContextProvider,
  useOnboardingContext,
} from 'features/onboarding/contexts/OnboardingContext'
import styles from './OnboardingLayout.module.scss'
import { ReactComponent as LogoSvg } from './images/logo.svg'

type Props = {
  children: React.ReactNode
  redirectIfOnboardingComplete?: boolean
  title?: string
} & Component

function OnboardingLayout({
  children,
  className,
  redirectIfOnboardingComplete = true,
  title,
}: Props) {
  const globalContext = useGlobalContext()
  const onboardingContext = useOnboardingContext()

  React.useEffect(() => {
    if (!globalContext.user?.isOnboardingCompleted || !redirectIfOnboardingComplete) return

    navigate(paths.HOME_AUTHED)

    // check only on mount so we don't redirect on submit
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectIfOnboardingComplete])

  return (
    <div className={classNames('OnboardingLayout', styles.this, className)}>
      <Head bodyAttributes={{ class: 'body---show-help-button' }} />
      <header className={styles.header}>
        <Container full className={styles['header--container']}>
          <LogoSvg aria-label="Bold" className={styles.logo} />
          {(onboardingContext.title || title) && (
            <Text element="div" weight="medium" className={styles['title-and-progress']}>
              <Text element="h1">
                {!_.isEmpty(onboardingContext.title) ? onboardingContext.title : title}
              </Text>
              {onboardingContext.progress ? (
                <Text color="ink200" wrap={false}>
                  {onboardingContext.progress}% Completed
                </Text>
              ) : undefined}
            </Text>
          )}
        </Container>
      </header>
      <View className={styles.main}>{children}</View>
    </div>
  )
}

export default function OnboardingLayoutWithContext(props: Props) {
  return (
    <OnboardingContextProvider>
      <OnboardingLayout {...props} />
    </OnboardingContextProvider>
  )
}
