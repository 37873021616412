import { Form, Formik } from 'formik'
import _ from 'lodash'
import React from 'react'
import * as Yup from 'yup'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Field2 from 'components/Field2'
import FieldCheckboxButtons from 'components/FieldCheckboxButtons'
import FieldNumber from 'components/FieldNumber'
import FieldTime from 'components/FieldTime'
import FormValidationNotification from 'components/FormValidationNotification'
import Label from 'components/Label'
import Row from 'components/Row'
import statusCodes from 'constants/status-codes'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as adminApiRequest from 'features/admin/libs/admin-api-request'
import * as notifications from 'libs/notifications'
import * as validations from 'libs/validations'

type Props = {
  userId: string
}

export default function AdminUsersGoalAndRemindersForm({ userId }: Props) {
  const globalContext = useGlobalContext()
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [existingGoalExerciseFrequency, setExistingGoalExerciseFrequency] = React.useState()
  const [existingReminderDays, setExistingReminderDays] = React.useState()
  const [existingReminderTime, setExistingReminderTime] = React.useState()
  const [existingReminderEmailConsent, setExistingReminderEmailConsent] = React.useState()
  const [existingReminderSmsConsent, setExistingReminderSmsConsent] = React.useState()

  React.useEffect(() => {
    async function getUserGoalAndReminders() {
      const result = await adminApiRequest.getUserGoal(userId)
      const userGoal = result?.data
      const days = userGoal?.reminderDays || []
      const time = userGoal?.reminderTimeExact || null
      const emailConsent = userGoal?.reminderEmailConsent
      const smsConsent = userGoal?.reminderSmsConsent
      const frequency = userGoal?.goalExerciseFrequency || 0

      setExistingGoalExerciseFrequency(frequency)
      setExistingReminderDays(days)
      setExistingReminderTime(time)
      setExistingReminderSmsConsent(smsConsent)
      setExistingReminderEmailConsent(emailConsent)
      setIsLoaded(true)
    }
    getUserGoalAndReminders()
  }, [userId])

  async function submit(values, formikActions) {
    const result = await adminApiRequest.updateUserGoal(userId, {
      ...values,
      source: 'Admin set exercise goal & reminders',
    })
    formikActions.setSubmitting(false)

    if (result.statusCode !== statusCodes.SUCCESS) {
      notifications.notifyError('Updating goal & reminders did not work')
      return
    }

    globalContext.analytics?.trackEvent('Admin changed user goal & reminders', {
      admin: globalContext.user.email,
      affectedUserId: userId,
      previousValues: {
        previousReminderDays: existingReminderDays,
        previousReminderTime: existingReminderTime,
        previousReminderEmailConsent: existingReminderEmailConsent,
        previousReminderSmsConsent: existingReminderSmsConsent,
        previousGoalExerciseFrequency: existingGoalExerciseFrequency,
      },
      newValues: values,
    })

    notifications.notifySuccess('Goal & reminders updated!')
  }

  async function cancelReminders() {
    const result = await adminApiRequest.updateUserGoal(userId, {
      reminderDays: null,
      reminderTimeExact: null,
      reminderEmailConsent: null,
      reminderSmsConsent: null,
    })

    if (result.statusCode !== statusCodes.SUCCESS) {
      notifications.notifyError('Removing reminders did not work')
      return
    }

    notifications.notifySuccess('Reminders removed!')
  }

  async function handleSubmit(values, formikActions) {
    if (!_.isEmpty(values.reminderDays) && _.includes(values.reminderDays, 'None')) {
      _.remove(values.reminderDays, (value) => value === 'None')
    }

    if (_.isEmpty(values.reminderDays)) values.reminderDays = ['None']

    return submit(values, formikActions)
  }

  const fieldProps = {
    align: 'left',
    size: 'small',
  }

  if (!isLoaded) return null

  return (
    <Formik
      initialValues={{
        goalExerciseFrequency: existingGoalExerciseFrequency,
        reminderDays: existingReminderDays,
        reminderTimeExact: existingReminderTime,
        reminderEmailConsent: existingReminderEmailConsent,
        reminderSmsConsent: existingReminderSmsConsent,
      }}
      validationSchema={Yup.object({
        reminderDays: validations.REMINDERS_DAYS,
        reminderTimeExact: validations.REQUIRED,
      })}
      onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form>
          <FormValidationNotification />
          <FieldNumber
            {...fieldProps}
            label="Goal number of workouts per week"
            name="goalExerciseFrequency"
          />
          <FieldCheckboxButtons
            {...fieldProps}
            label="Day(s) of the week"
            name="reminderDays"
            options={[
              {
                text: 'Sunday',
                value: 'Sunday',
              },
              {
                text: 'Monday',
                value: 'Monday',
              },
              {
                text: 'Tuesday',
                value: 'Tuesday',
              },
              {
                text: 'Wednesday',
                value: 'Wednesday',
              },
              {
                text: 'Thursday',
                value: 'Thursday',
              },
              {
                text: 'Friday',
                value: 'Friday',
              },
              {
                text: 'Saturday',
                value: 'Saturday',
              },
            ]}
          />
          <FieldTime {...fieldProps} label="Time of the day" name="reminderTimeExact" />
          <Row size="xsmall">
            <Label>How to send reminders</Label>
            <Field2 label="Email" flush name="reminderEmailConsent" type="checkbox" />
            <Field2 label="Text message" flush name="reminderSmsConsent" type="checkbox" />
          </Row>
          <Buttons align="right">
            <Button color="white" disabled={formikProps.isSubmitting} onClick={cancelReminders}>
              Remove reminders
            </Button>
            <Button disabled={formikProps.isSubmitting} onClick={formikProps.submitForm}>
              Update
            </Button>
          </Buttons>
        </Form>
      )}
    </Formik>
  )
}
