import { useNavigate, useParams } from '@reach/router'
import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'
import Fieldset from 'components/Fieldset'
import Input from 'components/Input'
import Label from 'components/Label'
import Loader from 'components/Loader'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import View from 'components/View'
import * as adminApiRequest from 'features/admin/libs/admin-api-request'
import AdminUsersDelete from './components/AdminUsersDelete'
import AdminUsersGoalAndRemindersForm from './components/AdminUsersGoalAndRemindersForm'
import AdminUsersList from './components/AdminUsersList'
import AdminUsersPause from './components/AdminUsersPause'
import AdminUsersProfileForm from './components/AdminUsersProfileForm'
import AdminUsersProgramChange from './components/AdminUsersProgramChange'

export default function AdminUsers() {
  const [email, setEmail] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [selectedUserId, setSelectedtUserId] = React.useState()
  const [users, setUsers] = React.useState([])
  const { id } = useParams()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (id) setSelectedtUserId(id)
  }, [id])

  function handleInputChange(event) {
    setEmail(event.target.value)
  }

  async function setUser(user) {
    navigate(`/app/admin/users/${user.id}`)
  }

  async function searchForUsers() {
    setLoading(true)

    const userResponse = await adminApiRequest.getUsers(encodeURIComponent(email.trim()))

    setEmail('')
    setLoading(false)
    setUsers(userResponse.data)
    setSelectedtUserId(null)
  }

  if (loading) {
    return <Loader />
  }

  return (
    <View className="AdminUsers">
      <Row>
        <Container align="left" flush size="small">
          <Label>Search by email</Label>
          <MediaObject
            figure={<Button onClick={searchForUsers}>Search users</Button>}
            figurePosition="right"
            size="small">
            <Input type="text" onChange={handleInputChange} />
          </MediaObject>
        </Container>
      </Row>
      {selectedUserId ? (
        <Container size="large">
          <Fieldset title="Profile">
            <AdminUsersProfileForm userId={selectedUserId} />
          </Fieldset>
          <Fieldset title="Program">
            <AdminUsersProgramChange userId={selectedUserId} />
          </Fieldset>
          <Fieldset title="Goal and reminders">
            <AdminUsersGoalAndRemindersForm userId={selectedUserId} />
          </Fieldset>
          <Fieldset title="Pause">
            <AdminUsersPause userId={selectedUserId} />
          </Fieldset>
          <Fieldset title="Deletion">
            <AdminUsersDelete userId={selectedUserId} />
          </Fieldset>
        </Container>
      ) : (
        <AdminUsersList users={users} setUser={setUser} />
      )}
    </View>
  )
}
