import React from 'react'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Heading2 from 'components/Heading2'
import View from 'components/View'
import paths from 'constants/paths'

export default function AdminLanding() {
  const buttons = [
    {
      children: 'Users',
      to: paths.ADMIN_USERS,
    },
    {
      children: 'Create collection / challenge',
      to: paths.ADMIN_COLLECTION_CREATE,
    },
    {
      children: 'Live / rerun schedule',
      to: paths.ADMIN_LESSON_STREAMS,
    },
    {
      children: 'Recurring live classes',
      to: paths.ADMIN_LESSON_REGIMENS,
    },
    {
      children: 'Enterprise',
      to: paths.ADMIN_ENTERPRISE,
    },
    {
      children: 'Humana Marketing Report',
      to: paths.ADMIN_MARKETING_REPORT,
    },
  ]

  return (
    <View className="AdminLanding">
      <Container size="xxlarge">
        <Heading2>Welcome Admin!</Heading2>
        <Buttons>
          {buttons.map((item) => (
            <Button {...item} color="white" key={item.to} />
          ))}
        </Buttons>
      </Container>
    </View>
  )
}
