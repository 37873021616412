/* eslint-disable func-names */
import classNames from 'classnames'
import React from 'react'
import Button from 'components/Button'
import * as events from 'constants/events'
import facebook from 'constants/facebook'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import styles from './FacebookShareButton.module.scss'
import { ReactComponent as LogoSvg } from './images/logo.svg'

type Props = {
  href: string
  quote: string
} & Component

export default function FacebookShareButton({ className, href, quote }: Props) {
  const globalContext = useGlobalContext()

  React.useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: facebook.APP_ID,
        cookie: true,
        version: 'v3.2',
        xfbml: true,
      })
      const fbInitEvent = new Event('FBObjectReady')
      document.dispatchEvent(fbInitEvent)
    }
    ;(function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      const js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      if (fjs && fjs.parentNode) {
        fjs.parentNode.insertBefore(js, fjs)
      }
    })(document, 'script', 'facebook-jssdk')
  }, [])

  const handleClick = () => {
    globalContext.analytics?.trackEvent(events.REFERRALS_FACEBOOK_BUTTON_CLICK)

    if (!window.FB) return

    // For testing, we need to sign in as a test user on Facebook and pass an href value that does not include localhost
    window.FB.ui(
      {
        href,
        method: 'share',
        quote,
      },
      // eslint-disable-next-line no-unused-vars
      (response) => {}
    )
  }

  return (
    <Button
      icon={<LogoSvg className={styles.logo} />}
      className={classNames('FacebookShareButton', styles.this, className)}
      onClick={handleClick}>
      Share
    </Button>
  )
}
