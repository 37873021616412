import classNames from 'classnames'
import React from 'react'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './BoxHeader.module.scss'

type Props = {
  children: React.ReactNode
} & Component

export default function BoxHeader({ children, className }: Props) {
  return (
    <Text
      color="white"
      element="header"
      className={classNames('BoxHeader', styles.this, className)}>
      {children}
    </Text>
  )
}
