import classNames from 'classnames'
import { navigate } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Box from 'components/Box'
import BoxHeader from 'components/BoxHeader'
import Button from 'components/Button'
import Carousel from 'components/Carousel'
import Heading2 from 'components/Heading2'
import Image from 'components/Image'
import Loader from 'components/Loader'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Tag from 'components/Tag'
import Tags from 'components/Tags'
import Text from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as assessments from 'libs/assessments'
import * as assessmentsApiRequest from 'libs/assessments-api-request'
import type { Lesson } from 'libs/lesson'
import * as lessonLib from 'libs/lesson'
import type { ProgramBlock as ProgramBlockType } from 'libs/program'
import { useActiveProgramProgress, useLessonsForBlock, useProgram } from 'libs/program-query'
import styles from './ProgramBlock.module.scss'
import { ReactComponent as CheckmarkCircleSvg } from './images/checkmark-circle.svg'
import { ReactComponent as ChevronDownSvg } from './images/chevron---down.svg'
import { ReactComponent as ChevronUpSvg } from './images/chevron---up.svg'

export type Props = {
  data: ProgramBlockType
  lessonOverride: any
  // eslint-disable-next-line no-unused-vars
  onLessonClick: (lesson: any) => void
  onLessonSkipped: any
  playSkippedLesson: any
  postLessonState: Boolean
}

export default function ProgramBlock({
  data,
  lessonOverride,
  onLessonClick,
  onLessonSkipped,
  playSkippedLesson,
  postLessonState,
}: Props) {
  const globalContext = useGlobalContext()
  const { data: programData, mutate: mutateProgram } = useProgram()
  const { data: programProgressData, mutate: mutateProgramProgress } = useActiveProgramProgress()
  const program = programData?.data
  const programProgress = programProgressData?.data

  const blockIndex = data.exercisePrograms.programExerciseBlock.indexInProgram
  const hasStartedProgram = programProgress?.numClassesCompleted !== 0
  const [assessment, setAssessment] = React.useState(null)
  const [isAssessmentCompleted, setIsAssessmentCompleted] = React.useState(false)
  const [isCurrentBlock, setIsCurrentBlock] = React.useState()
  const [lessonCurrentIndex, setLessonCurrentIndex] = React.useState()
  const [showLessons, setShowLessons] = React.useState()
  const { data: blockLessonsData, mutate: mutateBlockLessons } = useLessonsForBlock(data.id, {
    conditional: showLessons,
  })
  const blockLessons = blockLessonsData?.data

  React.useEffect(() => {
    setIsCurrentBlock(blockIndex === program.currentBlockIndex)
  }, [program, blockIndex])

  React.useEffect(() => {
    if (!blockLessons || _.isEmpty(blockLessons)) return
    mutateBlockLessons()
    mutateProgramProgress()

    let currentIndex = _.findIndex(
      blockLessons,
      (item) => item.userView?.status !== 'completed' && item.userView?.status !== 'skipped'
    )

    if (lessonOverride && !postLessonState) {
      currentIndex = _.findIndex(blockLessons, (item) => item.id === lessonOverride?.id)
    }

    setLessonCurrentIndex(currentIndex)

    // handle end of block
    if (
      _.size(
        blockLessons.filter(
          (item: Lesson) =>
            item.userView?.status === 'completed' || item.userView?.status === 'skipped'
        )
      ) === _.size(blockLessons)
    ) {
      mutateProgram() // to update currentBlockIndex
    }
  }, [
    blockLessons,
    lessonOverride,
    postLessonState,
    mutateBlockLessons,
    mutateProgram,
    mutateProgramProgress,
  ])

  React.useEffect(() => {
    if (onLessonSkipped || playSkippedLesson) {
      mutateBlockLessons()
      mutateProgram()
    }
  }, [onLessonSkipped, mutateBlockLessons, mutateProgram, playSkippedLesson])

  const handleToggleLessonList = async () => {
    globalContext.analytics?.trackEvent(
      `Program: Block: ${showLessons ? 'Hide' : 'View'} Classes Button: Click`
    )

    setShowLessons(!showLessons)
  }

  React.useEffect(() => {
    async function getAssessments() {
      const assessmentForBlock = assessments.getByInternalId(data.assessmentOnCompletion)
      if (!assessmentForBlock || _.isEmpty(assessmentForBlock)) return

      const allAssessments = await assessmentsApiRequest.getAssessments(globalContext.user?.id)
      setAssessment(assessmentForBlock)
      setIsAssessmentCompleted(
        !_.isEmpty(allAssessments.find((item) => item.id === assessmentForBlock.id)?.lastRecordDate)
      )
    }
    getAssessments()
    setShowLessons(isCurrentBlock)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentBlock, globalContext.user?.id])

  function handleLessonClick(lesson: Lesson) {
    globalContext.analytics?.trackEvent('Program: Block: Lesson: Click')
    onLessonClick(lesson)
    mutateBlockLessons()
    mutateProgram()
  }

  let headerRight: React.ReactNode =
    isCurrentBlock && programProgress?.currentBlockNumClassesTotal
      ? `${programProgress?.currentBlockNumClassesCompleted ?? 0}/${
          programProgress?.currentBlockNumClassesTotal
        } completed`
      : ''

  if (program.currentBlockIndex > blockIndex) {
    headerRight = <TextWithIcon icon={<CheckmarkCircleSvg />}>Completed</TextWithIcon>
  }

  const isNextBlock = blockIndex === program.currentBlockIndex + 1

  const tagProps = {
    casing: 'normal',
    size: 'medium',
  }

  return (
    <Box
      color="white"
      header={
        <BoxHeader>
          <MediaObject
            figure={<Text weight="semibold">{headerRight}</Text>}
            center
            figurePosition="right"
            size="small">
            <div className={styles.tags}>
              <Tag {...tagProps} color="purple">
                Section {blockIndex + 1}
              </Tag>
              {isCurrentBlock && (
                <Tag {...tagProps} color="green">
                  {hasStartedProgram ? 'In progress' : 'Current'}
                </Tag>
              )}
              {isNextBlock && (
                <Tag {...tagProps} color="pink">
                  Up next
                </Tag>
              )}
            </div>
          </MediaObject>
        </BoxHeader>
      }
      className={classNames('ProgramBlock', styles.this)}>
      <Heading2 level={2} levelStyle={5}>
        {data.title}
      </Heading2>
      <Text element="p">{data.description}</Text>
      <Row size="xxsmall">
        <Button
          icon={showLessons ? <ChevronUpSvg /> : <ChevronDownSvg />}
          iconPosition="right"
          level="text"
          className={styles.button}
          onClick={handleToggleLessonList}>
          {showLessons ? 'Hide' : 'View'} classes
        </Button>
      </Row>
      {showLessons && (
        <div className={styles.lessons}>
          {_.isEmpty(blockLessons) ? (
            <Loader overlay={false} />
          ) : (
            <Carousel
              initialSlide={_.findIndex(
                blockLessons,
                (item) => item.userView?.status !== 'completed'
              )}
              loop={false}
              navigationProps={{
                background: true,
              }}
              showNavigation
              slidesOffsetBefore={12}
              slidesOffsetAfter={12}
              slidesPerView={2.15}>
              {blockLessons.map((lesson: Lesson, index) => {
                const classTypes = lesson.classType?.filter(Boolean)
                const image = lesson.videoThumbnail
                const isCompleted = lesson.userView?.status === 'completed'
                const isSkipped = lesson.userView?.status === 'skipped'
                const isCurrent =
                  !postLessonState &&
                  !isCompleted &&
                  !isSkipped &&
                  ((isCurrentBlock && index === lessonCurrentIndex) ||
                    lesson.id === lessonOverride?.id)

                return (
                  <div
                    key={lesson.id}
                    className={classNames(
                      styles.lesson,
                      isCurrent && styles['lesson---current'],
                      isCompleted && styles['lesson---completed']
                    )}
                    onClick={() => handleLessonClick(lesson)}>
                    <Row size="xsmall">
                      <div className={styles['lesson--image-wrap']}>
                        <Image src={image} full className={styles['lesson--image']} />
                        <div className={styles['lesson--image-overlay']}>
                          <Tags data={[lessonLib.getStatus(lesson)]} color="black" />
                        </div>
                      </div>
                    </Row>
                    <Row size="xxxsmall">
                      <Text element="h3" weight="medium">
                        {lesson.videoTitle}
                      </Text>
                    </Row>
                    <Text size="small">
                      {lesson.instructorName}
                      {!_.isEmpty(classTypes) && (
                        <>
                          {' • '}
                          {classTypes.join(', ')}
                        </>
                      )}
                    </Text>
                  </div>
                )
              })}
              {assessment && (
                <div
                  className={styles.lesson}
                  onClick={() => navigate(`${paths.ASSESSMENTS}${assessment.internalId}/`)}>
                  <Row size="xsmall">
                    <div className={styles['lesson--image-wrap']}>
                      <Image src={assessment.photo2x} className={styles['lesson--image']} />
                      <div className={styles['lesson--image-overlay']}>
                        <Tags
                          data={[isAssessmentCompleted ? 'Completed' : undefined]}
                          color="black"
                        />
                      </div>
                    </div>
                  </Row>
                  <Row size="xxxsmall">
                    <Text element="h3" weight="medium">
                      {assessment.title} assessment
                    </Text>
                  </Row>
                  <Text size="small">{assessment.trainer}</Text>
                </div>
              )}
            </Carousel>
          )}
        </div>
      )}
    </Box>
  )
}
