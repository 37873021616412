import phoneNumbers from '@bold/common/constants/phone-numbers'
import React from 'react'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Link from 'components/Link'
import ModalBox from 'components/ModalBox'
import emails from 'constants/emails'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as programs from 'libs/programs'

type Props = {
  onSubmit: () => void
}

export default function ProgramChangeModal({ onSubmit }: Props) {
  const globalContext = useGlobalContext()
  const [showAlternateText, setShowAlternateText] = React.useState(false)

  React.useEffect(() => {
    const exerciseProgram = globalContext.user.userExerciseProgram.exerciseProgram
    if (!exerciseProgram.ifTooEasy && !exerciseProgram.ifTooHard) {
      globalContext.analytics?.trackEvent(
        'Change Program Modal: missing alternate program options',
        {
          currExerciseProgramId: exerciseProgram.id,
          currExerciseProgramName: exerciseProgram.externalName,
        }
      )
      setShowAlternateText(true)
    }
  }, [globalContext.analytics, globalContext.user])

  return (
    <ModalBox
      title="Try a new program"
      subtext={
        showAlternateText ? (
          <>
            Reach out to our team at <Link to={emails.DEFAULT} /> or call{' '}
            <Link to={phoneNumbers.DEFAULT} /> for assistance.
          </>
        ) : (
          'To help us find a better program for you, how difficult was your current program?'
        )
      }>
      {!showAlternateText && (
        <Container flush size="small">
          <Buttons align="center" equal>
            <Button color="white" onClick={() => onSubmit(programs.CLASS_TOO_EASY)}>
              Too easy
            </Button>
            <Button color="white" onClick={() => onSubmit(programs.CLASS_TOO_HARD)}>
              Too hard
            </Button>
          </Buttons>
        </Container>
      )}
    </ModalBox>
  )
}
