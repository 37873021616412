export function amount(value: string) {
  if (!value) return ''

  return Number.parseFloat(value).toFixed(2).replace('.00', '')
}

export function display(value: string) {
  if (!value) return ''

  return `$${amount(value)}`
}
