import classNames from 'classnames'
import React from 'react'
import type { Component } from 'constants/types'
import styles from './BoldText.module.scss'
import { ReactComponent as LogoSvg } from './images/logo.svg'

type Props = Component

export default function BoldText({ className }: Props) {
  return (
    <>
      {' '}
      <LogoSvg className={classNames('BoldText', styles.this, className)} aria-label="Bold" />{' '}
    </>
  )
}
