import { Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import Button from 'components/Button'
import Field2 from 'components/Field2'
import Heading from 'components/Heading'
import statusCodes from 'constants/status-codes'
import * as adminApiRequest from 'features/admin/libs/admin-api-request'
import * as notifications from 'libs/notifications'
import * as validations from 'libs/validations'

type Props = {
  onSuccess: () => void
}

export default function EnterpriseUsageReporting({ onSuccess }: Props) {
  const [enterprises, setEnterprises] = React.useState([])

  React.useEffect(() => {
    async function fetchEnterprises() {
      const getEnterprises = await adminApiRequest.getEnterpriseList()
      setEnterprises(getEnterprises.data)
    }
    fetchEnterprises()
  }, [])

  async function submit(values, formikActions) {
    const { enterpriseId, year, month } = values
    const generatedReport = await adminApiRequest.generateMonthlyUsageReportForEnterprise(
      enterpriseId,
      year,
      month
    )
    if (generatedReport.statusCode === statusCodes.SUCCESS) {
      formikActions.resetForm()
      if (onSuccess) onSuccess(generatedReport)
    } else {
      console.error(generatedReport.statusCode)
      console.error(generatedReport.message)
      notifications.notifyError(generatedReport.message)
      return
    }
    const reportData = generatedReport.data
    const element = document.createElement('a')
    const file = new Blob([reportData.rawText], { type: reportData.format })
    element.href = URL.createObjectURL(file)
    element.download = reportData.filename
    document.body.append(element)
    element.click()

    formikActions.setSubmitting(false)
  }

  function handleSubmit(values, formikActions) {
    values.month = Number.parseInt(values.month, 10)
    submit(values, formikActions)
  }

  const enterpriseListDropdown = !enterprises
    ? []
    : enterprises
        .filter((enterprise) => enterprise.name === 'Renew Active' || enterprise.name === 'OnePass')
        .map((enterprise) => ({
          label: enterprise.name,
          value: enterprise.id,
        }))

  const monthDropdown = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ].map((month, index) => ({
    label: month,
    value: (index + 1).toString(),
  }))

  return (
    <div>
      <Heading level={2}>Generate usage report (Renew Active & One Pass only)</Heading>
      <Formik
        initialValues={{
          year: 2022,
        }}
        validationSchema={Yup.object({
          enterpriseId: validations.REQUIRED,
          month: Yup.number().positive().integer().required(),
          year: Yup.number().positive().integer().required(),
        })}
        onSubmit={handleSubmit}>
        {(formikProps) => (
          <Form>
            <Field2
              label="Enterprise"
              entity="partner"
              name="enterpriseId"
              type="select"
              options={enterpriseListDropdown}
            />
            <Field2 label="Year" entity="year" min="2017" max="2025" name="year" type="number" />
            <Field2
              label="Month"
              entity="month"
              name="month"
              type="select"
              options={monthDropdown}
            />
            <Button
              id="generateReport"
              disabled={formikProps.isSubmitting}
              onClick={formikProps.submitForm}>
              Generate report
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
