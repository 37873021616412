import phoneNumbers from '@bold/common/constants/phone-numbers'
import pluralize from 'pluralize'
import React from 'react'
import Box from 'components/Box'
import Image from 'components/Image'
import Link from 'components/Link'
import Modal, { type ModalProps } from 'components/Modal'
import ModalConfirm from 'components/ModalConfirm'
import Row from 'components/Row'
import Text from 'components/Text'
import emails from 'constants/emails'
import { useGlobalContext } from 'contexts/GlobalContext'
import programCompleteBadgeImage from 'images/badges/program-complete-badge.svg'
import * as notifications from 'libs/notifications'
import * as programs from 'libs/programs'
import * as userApiRequest from 'libs/user-api-request'
import styles from './ProgramProgressionModals.module.scss'

type Props = {
  isChanging?: boolean
  numClassesCompleted?: number
  onClose?: Function
  program?: {
    alternateProgram?: {
      description: string
      title: string
    }
    similarProgram?: {
      description: string
      title: string
    }
  }
} & Partial<ModalProps>

export default function ProgramProgressionModals({
  isChanging,
  numClassesCompleted,
  onClose,
  program,
  ...props
}: Props) {
  const globalContext = useGlobalContext()
  const [showBadge, setShowBadge] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [showProgramSelection, setShowProgramSelection] = React.useState(isChanging)

  React.useEffect(() => {
    if (isChanging) return
    setShowModal(true)

    globalContext.analytics?.trackEvent('Start program complete flow', {
      exerciseProgramTitle: programs.getProgramTitle(program),
      currentExerciseProgramId: program?.exerciseProgram?.id,
    })
  }, [globalContext.analytics, program, isChanging])

  const commonProps = {
    size: 'small',
    className: 'ProgramProgressionModals',
  }

  const handleConfirmCompletion = () => {
    setShowBadge(true)
    globalContext.analytics?.trackEvent('Program complete flow first modal click')
  }

  const handleConfirmCollectBadge = () => {
    setShowProgramSelection(true)
    setShowModal(false)
    globalContext.analytics?.trackEvent('Program complete flow second modal click', {
      similarProgramId: program?.similarProgram?.id,
      alternateProgramId: program?.alternateProgram?.id,
    })
  }

  const handleProgramClick = async (clickedProgram: {
    id: string
    title: string
    description: string
  }) => {
    const response = await userApiRequest.userExerciseProgramProgression(clickedProgram.id)

    if (response.statusCode !== 200) {
      globalContext.analytics?.trackEvent(
        'Program complete flow - failed to progress user to new program',
        {
          selectedProgramId: clickedProgram.id,
        }
      )
      notifications.notifyError(
        `Sorry we couldn’t change your program right now. Please contact us at ${emails.DEFAULT} or call 833-701-1545 (toll-free).`
      )
      setShowProgramSelection(false)
      setShowModal(false)
      return
    }

    globalContext.analytics?.trackEvent('Program complete flow select program', {
      selectedProgramId: clickedProgram.id,
      title: programs.getProgramTitle(clickedProgram),
      description: clickedProgram.description,
    })
    await globalContext.updateUser()
    setShowProgramSelection(false)

    //TODO change this. Ideally, reloading the page is temp fix.
    window.location.reload()
  }

  const handleProgramSelectionClose = () => {
    setShowProgramSelection(false)
    if (onClose) onClose()
  }

  const similarProgram = program?.similarProgram
  const alternateProgram = program?.alternateProgram
  const count = [similarProgram, alternateProgram].filter(Boolean).length

  return (
    <>
      <ModalConfirm
        {...props}
        {...commonProps}
        title="Well done!"
        confirmText="Collect badge"
        confirmButtonProps={{ color: 'yellow' }}
        hideCancel
        hideClose
        isOpen={showModal}
        onConfirm={handleConfirmCompletion}
        onRequestClose={() => setShowModal(false)}>
        <Text align="left" element="p" flush>
          Congrats, you finished all {numClassesCompleted} lessons in your program!
        </Text>
      </ModalConfirm>

      <ModalConfirm
        {...props}
        {...commonProps}
        hideClose
        hideCancel
        confirmText="Continue"
        confirmButtonProps={{ color: 'yellow' }}
        isOpen={showBadge}
        onConfirm={handleConfirmCollectBadge}
        onRequestClose={() => setShowBadge(false)}>
        <Row size="small">
          <Image src={programCompleteBadgeImage} alt="Program completion badge" />
        </Row>
        <Text element="p" weight="medium">
          You earned a program completion badge!
        </Text>
      </ModalConfirm>

      {count === 0 && (
        <Modal
          {...props}
          {...commonProps}
          hideClose
          hideCancel
          isOpen={showProgramSelection}
          onRequestClose={handleProgramSelectionClose}>
          <Box color="grey">
            <Row size="small">
              <Text element="h2" size="xlarge" align="center" weight="medium">
                Get your next program
              </Text>
            </Row>
            <Text element="p" flush>
              Talk to a member of Team Bold to start your next program. Contact us at{' '}
              <Link to={phoneNumbers.DEFAULT} /> or <Link to={emails.DEFAULT} />.
            </Text>
          </Box>
        </Modal>
      )}

      {(similarProgram || alternateProgram) && (
        <Modal
          {...props}
          {...commonProps}
          hideClose
          hideCancel
          isOpen={showProgramSelection}
          onConfirm={handleProgramSelectionClose}
          onRequestClose={handleProgramSelectionClose}>
          <Box color="grey">
            <Row size="small">
              <Text element="h2" size="xlarge" align="center" weight="medium">
                {`${count > 1 ? `Pick your` : `Your`} next program`}
              </Text>
            </Row>
            <Text element="p">
              {`${count > 1 ? `Choose from 2` : `Your`} new ${pluralize('program', count)} ${
                count > 1 ? `that build` : `builds`
              } off of your last program. Click below to get started.`}
            </Text>
            {similarProgram && (
              <Row size="small">
                <Box
                  align="left"
                  color="grey"
                  className={styles.programSuggestion}
                  onClick={() => handleProgramClick(similarProgram)}>
                  <Row size="xxsmall">
                    <Text element="h3" size="large" weight="medium">
                      {programs.getProgramTitle(similarProgram)}
                    </Text>
                  </Row>
                  <Text element="p">{similarProgram.description}</Text>
                </Box>
              </Row>
            )}
            {alternateProgram && (
              <Row size="small">
                <Box
                  color="grey"
                  className={styles.programSuggestion}
                  onClick={() => handleProgramClick(alternateProgram)}>
                  <Row size="xxsmall">
                    <Text element="h3" size="large" align="left" weight="medium">
                      {programs.getProgramTitle(alternateProgram)}
                    </Text>
                  </Row>
                  <Text element="p" align="left">
                    {alternateProgram.description}
                  </Text>
                </Box>
              </Row>
            )}
            <Text element="p" flush>
              Need help? Contact us at <Link to={phoneNumbers.DEFAULT} /> or{' '}
              <Link to={emails.DEFAULT} />.
            </Text>
          </Box>
        </Modal>
      )}
    </>
  )
}
