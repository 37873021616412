import React from 'react'
import Toggle from 'react-toggle'
import Button from 'components/Button'
import Field from 'components/Field'
import Input from 'components/Input'
import Label from 'components/Label'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import statusCodes from 'constants/status-codes'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as adminApiRequest from 'features/admin/libs/admin-api-request'
import * as enterpriseLib from 'libs/enterprise'
import * as notifications from 'libs/notifications'
import AdminUsersProfileFormModals from './components/AdminUsersProfileFormModals'

type Props = {
  userId: string
}

type IndividualField = {
  currentValue: string
  fieldType: string
}

export default function ProfileForm({ userId }: Props) {
  const globalContext = useGlobalContext()
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [enterpriseMemberId, setEnterpriseMemberId] = React.useState('')
  const [enterpriseKey, setEnterpriseKey] = React.useState(null)
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [smsConsent, setSmsConsent] = React.useState(false)
  const [emailConsent, setEmailConsent] = React.useState(false)
  const [carelinxConsent, setCarelinxConsent] = React.useState(false)
  const [selectedField, setSelectedField] = React.useState<IndividualField>(null)

  React.useEffect(() => {
    async function getData() {
      const result = await adminApiRequest.getUser(userId)
      const user = result?.data
      if (!user) return
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setEmail(user.email)
      setPhoneNumber(user.phoneNumber)
      setSmsConsent(user.smsConsent)
      setEmailConsent(user.emailConsent)
      setEnterpriseMemberId(user.enterpriseMemberId)
      if (!user.enterpriseMemberId) return
      const enterpriseMemberResult = await adminApiRequest.getEnterpriseMember(
        user.enterpriseMemberId
      )
      const enterpriseMember = enterpriseMemberResult?.data
      setEnterpriseKey(enterpriseMember?.enterprise?.internalId)
      setCarelinxConsent(enterpriseMember?.configuration?.carelinxConsent)
    }
    getData()
  }, [userId, selectedField])

  function handleCompletion() {
    setSelectedField(null)
  }

  async function handleLinkEnterprise() {
    const response = await adminApiRequest.linkUserWithEnterpriseMember({
      enterpriseMemberId,
      userId,
    })

    if (response.statusCode !== statusCodes.POST_SUCCESS) {
      const message = response.message || response.error.message
      notifications.notifyError(message)
      return
    }

    notifications.notifySuccess('User linked with enterprise member!')
  }

  async function updateConsent(fieldType: string, previousValue: boolean) {
    const newValue = !previousValue
    const response = await adminApiRequest.updateUser(userId, { [fieldType]: newValue })

    if (response.statusCode !== statusCodes.POST_SUCCESS) {
      const message = response.message || response.error.message
      notifications.notifyError(message)
      return
    }

    notifications.notifySuccess('Field updated!')
    if (fieldType === 'smsConsent') {
      setSmsConsent(newValue)
    } else {
      setEmailConsent(newValue)
    }

    globalContext.analytics?.trackEvent('Admin changed user profile', {
      admin: globalContext.user.email,
      affectedUserId: userId,
      fieldType,
      previousValue,
      newValue,
    })
  }

  async function updateCarelinxConsent(previousValue: boolean) {
    const newCarelinxConsent = !previousValue
    const newConfiguration = { carelinxConsent: newCarelinxConsent }
    const response = await adminApiRequest.updateEnterpriseMemberConfiguration(
      enterpriseMemberId,
      newConfiguration
    )
    if (response.statusCode !== statusCodes.POST_SUCCESS) {
      const message = response.message || response.error.message
      notifications.notifyError(message)
      return
    }

    notifications.notifySuccess('Field updated!')
    setCarelinxConsent(newCarelinxConsent)

    globalContext.analytics?.trackEvent('Admin updated enterprise member carelinx consent', {
      admin: globalContext.user.email,
      affectedUserId: userId,
      carelinxConsent: newCarelinxConsent,
    })
  }

  return (
    <>
      <AdminUsersProfileFormModals
        fieldInfo={selectedField}
        onComplete={handleCompletion}
        userId={userId}
      />
      <Field>
        <MediaObject
          figure={
            <Button
              color="white"
              onClick={() =>
                setSelectedField({
                  currentValue: firstName,
                  fieldType: 'firstName',
                })
              }>
              Edit
            </Button>
          }
          figurePosition="right"
          style={{ alignItems: 'flex-end' }}>
          <Label>First name</Label>
          <Input disabled value={firstName} />
        </MediaObject>
      </Field>
      <Field>
        <MediaObject
          figure={
            <Button
              color="white"
              onClick={() =>
                setSelectedField({
                  currentValue: lastName,
                  fieldType: 'lastName',
                })
              }>
              Edit
            </Button>
          }
          figurePosition="right"
          style={{ alignItems: 'flex-end' }}>
          <Label>Last name</Label>
          <Input disabled value={lastName} />
        </MediaObject>
      </Field>
      <Field>
        <MediaObject
          figure={
            <Button
              color="white"
              onClick={() =>
                setSelectedField({
                  currentValue: email,
                  fieldType: 'email',
                })
              }>
              Edit
            </Button>
          }
          figurePosition="right"
          style={{ alignItems: 'flex-end' }}>
          <Label>Email</Label>
          <Input disabled value={email} />
        </MediaObject>
      </Field>
      <Field>
        <MediaObject
          figure={
            <Button
              color="white"
              onClick={() =>
                setSelectedField({
                  currentValue: phoneNumber,
                  fieldType: 'phoneNumber',
                })
              }>
              Edit
            </Button>
          }
          figurePosition="right"
          style={{ alignItems: 'flex-end' }}>
          <Label>Phone Number</Label>
          <Input disabled value={phoneNumber} />
        </MediaObject>
      </Field>
      <Field>
        <Label>Sms Consent</Label>
        <Toggle
          checked={smsConsent}
          icons={false}
          onChange={() => updateConsent('smsConsent', smsConsent)}
        />
      </Field>
      <Field>
        <Label>Email Consent</Label>
        <Toggle
          checked={emailConsent}
          icons={false}
          onChange={() => updateConsent('emailConsent', emailConsent)}
        />
      </Field>
      <br />
      <Row>
        <Text size="large" weight="medium">
          Enterprise Member Info
        </Text>
      </Row>
      <Field>
        <MediaObject
          figure={
            <Button color="white" onClick={handleLinkEnterprise}>
              Link
            </Button>
          }
          figurePosition="right"
          style={{ alignItems: 'flex-end' }}>
          <Label>Link with Enterprise Member ID</Label>
          <Input
            value={enterpriseMemberId}
            onChange={(event) => setEnterpriseMemberId(event.target.value)}
          />
        </MediaObject>
      </Field>
      {enterpriseKey === enterpriseLib.GROUPER_KEY && (
        <Field>
          <Label>(If applicable) Consents to Carelinx</Label>
          <Toggle
            checked={carelinxConsent}
            icons={false}
            onChange={() => updateCarelinxConsent(carelinxConsent)}
          />
        </Field>
      )}
    </>
  )
}
