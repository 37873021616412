import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import Image from 'components/Image'
import Row from 'components/Row'
import Text from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import type { Component } from 'constants/types'
import styles from './ButtonFilter2.module.scss'
import { ReactComponent as ChevronDownSvg } from './images/chevron---down.svg'
import { ReactComponent as ChevronUpSvg } from './images/chevron---up.svg'
import { ReactComponent as XSvg } from './images/x.svg'

type Option = {
  hide?: boolean
  icon?: React.ReactNode
  label: string
  value: string
}

type Props = {
  children: React.ReactNode
  onSelect: () => void
  options: Option[]
  value?: [] | string
} & Component

export default function ButtonFilter2({
  children,
  className,
  onClear,
  onSelect,
  options,
  title,
  value: propsValue,
  ...props
}: Props) {
  const [isOpen, setIsOpen] = React.useState(false)

  const getOption = React.useCallback(
    (newValue: [] | string) => {
      if (_.isEmpty(newValue)) return {}

      return options.find((item: Option) => _.isEqual(item.value, propsValue))
    },
    [options, propsValue]
  )

  const [value, setValue] = React.useState<Option | {}>(getOption(propsValue))

  React.useEffect(() => {
    if (_.isEmpty(propsValue)) return

    setValue(getOption(propsValue))
  }, [getOption, propsValue])

  const hasValue = !_.isEmpty(value)

  React.useEffect(() => {
    //if (hasValue) return
    setIsOpen(false)
  }, [value])

  function handleButtonClick() {
    if (hasValue) return
    setIsOpen(!isOpen)
  }

  function handleClearButtonClick(event) {
    event.stopPropagation()
    setValue({})
    onClear()
  }

  function handleOptionButtonClick(option: Option) {
    setValue(option)
    onSelect(option.value)
  }

  const optionsFiltered = options //.filter((item: Option) => !item.hide)

  return (
    <div {...props} className={classNames('ButtonFilter2', styles.this, className)}>
      <Row size="xsmall">
        <Button
          color="white"
          disabled={!hasValue && _.size(optionsFiltered) <= 1}
          faux={hasValue}
          full
          className={classNames(styles.button, hasValue && styles['button---faux'])}
          onClick={handleButtonClick}>
          <TextWithIcon
            icon={
              !_.isEmpty(value) && (
                <button
                  type="button"
                  aria-label="Clear"
                  className={styles['clear-button']}
                  onClick={handleClearButtonClick}>
                  <XSvg aria-hidden />
                </button>
              )
            }
            center
            gutterSize="xsmall">
            {value?.label || (
              <TextWithIcon
                icon={
                  isOpen ? (
                    <ChevronUpSvg className={styles['chevron-icon']} />
                  ) : (
                    <ChevronDownSvg className={styles['chevron-icon']} />
                  )
                }
                iconPosition="right">
                {children}
              </TextWithIcon>
            )}
          </TextWithIcon>
        </Button>
      </Row>
      <div className={classNames(styles.options, isOpen && styles['options---open'])}>
        <Text element="ul" className={styles['dropdown---grid']}>
          {optionsFiltered.map((item: Option) => (
            <li key={item.label} className={styles['dropdown--item']}>
              <TextWithIcon
                icon={<Image src={item.icon} className={styles['dropdown--item--icon']} />}
                align={item.icon ? 'left' : undefined}
                element="button"
                gutterSize="xsmall"
                type="button"
                weight="medium"
                //wrap={!item.icon}
                wrap={false}
                className={styles['dropdown--item--button']}
                onClick={() => handleOptionButtonClick(item)}>
                {item.label}
              </TextWithIcon>
            </li>
          ))}
        </Text>
      </div>
    </div>
  )
}
