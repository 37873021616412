import { navigate } from 'gatsby'
import React from 'react'
import Container from 'components/Container'
import * as events from 'constants/events'
import paths from 'constants/paths'
import statusCodes from 'constants/status-codes'
import storage from 'constants/storage'
import { useGlobalContext } from 'contexts/GlobalContext'
import OnboardingCheckinSingleForm from 'features/onboarding/components/OnboardingCheckinSingleForm'
import OnboardingLayout from 'features/onboarding/components/OnboardingLayout'
import useEventPageVisited from 'hooks/use-event-page-visited'
import { generateFbc } from 'libs/analytics'
import * as checkinApiRequest from 'libs/checkin-api-request'
import * as cookie from 'libs/cookie'
import * as eventTracking from 'libs/event-tracking'
import * as notifications from 'libs/notifications'
import * as user from 'libs/user'
import * as userApiRequest from 'libs/user-api-request'
import CheckinTracker from 'libs/checkins/checkin-tracker'

export default function OnboardingQuestions() {
  useEventPageVisited(events.ONBOARDING_QUESTIONS_PAGE)
  const globalContext = useGlobalContext()
  const [checkinTracker, setCheckinTracker] = React.useState({})
  const isSpecial = user.hasHomeSpecial()
  const isEligible =
    user.isEnterpriseUser(globalContext.user) && globalContext.user.enterpriseMember?.validated

  async function next() {
    if (globalContext.user?.onboardingCompletedDate) {
      // if user already already has an OB completed date, they are an existing member
      // who had to re-answer OB for some reason (like upgrading to clinical enterprise member from settings)
      navigate(paths.HOME_AUTHED, { replace: true })
    } else if (
      globalContext.user?.loginType === 'facebook' ||
      globalContext.user?.loginType === 'google'
    ) {
      // don't need to make a pswd
      navigate(
        isSpecial && !isEligible
          ? paths.ONBOARDING_ENTERPRISE_ELIGIBILITY
          : paths.ONBOARDING_PROGRAM_BUILD,
        { replace: true }
      )
    } else if (
      checkinTracker?.checkinCode?.includes('default_onboarding') ||
      checkinTracker?.checkinCode?.includes('because_onboarding')
    ) {
      // summary page, then to profile page
      navigate(paths.ONBOARDING_ASSESSMENT_RESULTS, { replace: true })
    } else {
      // TODO: improve flow
      // if user has a pswd but no OB completed date, they previously signed up and abandoned cart
      // and might've created a pswd through reset pswd flow, but they still need to do profile page
      // to do OB complete API call ... so for now, they'll just have to recreate pswd again
      navigate(paths.ONBOARDING_PROFILE, { replace: true })
    }
  }

  React.useEffect(() => {
    if (!globalContext.user) return

    async function getCheckin() {
      const response = await checkinApiRequest
        .getUserCheckin({
          type: 'onboarding',
          activeOnly: true,
          platform: 'web',
        })
        .catch((error) => {
          globalContext.analytics?.trackEvent(events.FAILED_CHECKIN_CALL, {
            error,
            from: paths.ONBOARDING_QUESTIONS,
            type: 'onboarding',
          })
          notifications.notifyError()
        })

      const { data } = response

      if (!data) {
        await globalContext.updateUser() //ensure updated info to see if eligible
        await next()
        return
      }

      setCheckinTracker(new CheckinTracker(globalContext.user?.id, data, globalContext.analytics))
    }
    getCheckin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalContext.user?.id, globalContext.analytics])

  React.useEffect(() => {
    const track = async () => {
      if (!checkinTracker.checkinCode) return
      await globalContext.analytics?.trackEvent(events.ONBOARDING_START, {
        checkinCode: checkinTracker.checkinCode,
      })
    }
    track()
  }, [checkinTracker.checkinCode, globalContext.analytics])

  function handleOnboardingError(error: any) {
    // handle when completeOB API call fails
    console.error(error)
    notifications.notifyError('Something went wrong submitting your responses')

    globalContext.analytics?.trackEvent(
      events.ONBOARDING_NOT_COMPLETE,
      {
        from: globalContext.user?.loginType,
        source: events.ONBOARDING_QUESTIONS_PAGE,
      },
      {
        userId: globalContext.user?.id,
      }
    )
  }

  async function finishOnboarding() {
    const response = await userApiRequest
      .completeOnboarding({
        dateOfBirth: globalContext.user.dateOfBirth,
        email: globalContext.user.email,
        firstName: globalContext.user.firstName,
        lastName: globalContext.user.lastName,
        phoneNumber: globalContext.user.phoneNumber,
        postalCode: globalContext.user.postalCode,
      })
      .catch((error: any) => {
        handleOnboardingError(error)
      })

    if (response.statusCode === statusCodes.POST_SUCCESS) {
      await globalContext.update({ user: response.data })
    } else {
      handleOnboardingError(response)
      return
    }

    const fbclid = cookie.getCookie(storage.FBC_LID) || null

    globalContext.analytics?.trackEvent(
      events.ONBOARDING_COMPLETE,
      {
        external_id: globalContext.user?.id,
        fbc: generateFbc(fbclid),
        from: globalContext.user?.loginType,
      },
      {
        userId: globalContext.user?.id,
      }
    )
    eventTracking.onboardingComplete()
  }

  async function success() {
    const loginType = globalContext.user?.loginType
    if (loginType === user.FACEBOOK_SRC_ID || loginType === 'google') {
      await finishOnboarding() // bc no profile page
    }

    await next()
  }

  async function handleSuccess() {
    await success()
  }

  async function handleError(answers = {}) {
    // handle when checkin couldn't be marked as complete in BE (isOnboardingCompleted still false anyway)
    globalContext.analytics?.trackEvent(events.ONBOARDING_FAILED, {
      ...answers,
    })
    notifications.notifyError(
      'Something went wrong submitting your responses. Please try again later.'
    )
  }

  return (
    <OnboardingLayout title="Preferences" className="OnboardingQuestions">
      <Container flush size="small">
        <OnboardingCheckinSingleForm
          checkinTracker={checkinTracker}
          onSuccess={handleSuccess}
          onError={handleError}
        />
      </Container>
    </OnboardingLayout>
  )
}
