import classNames from 'classnames'
import { navigate } from 'gatsby'
import React from 'react'
import CheckinSingleForm from 'components/CheckinSingleForm'
import Container from 'components/Container'
import Modal, { type ModalProps } from 'components/Modal'
import ModalBox from 'components/ModalBox'
import ModalConfirm from 'components/ModalConfirm'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as checkinApiRequest from 'libs/checkin-api-request'
import CheckinTracker from 'libs/checkins/checkin-tracker'

type Props = {
  checkinData: any
} & Partial<ModalProps>

export default function EnterpriseOctopusFullDoseCheckinModal({
  className,
  checkinData,
  ...props
}: Props) {
  const globalContext = useGlobalContext()
  const [checkinTracker, setCheckinTracker] = React.useState<CheckinTracker | null>(null)
  const [isCheckinSubmitted, setIsCheckinSubmitted] = React.useState(false)

  React.useEffect(() => {
    async function getCheckin() {
      if (checkinData == null) return
      setCheckinTracker(
        new CheckinTracker(globalContext.user?.id, checkinData, globalContext.analytics)
      )
    }
    if (checkinTracker == null) getCheckin()
  }, [checkinData, checkinTracker, globalContext.analytics, globalContext.user?.id])

  function submitCheckin() {
    globalContext.analytics?.trackEvent(events.ALTERNATE_BALANCE_ASSESSMENT_COMPLETE)

    setIsCheckinSubmitted(true)
  }

  async function handleSuccess() {
    const response = await checkinApiRequest.getLatestUserCheckinResponses()
    if (response.statusCode !== 200 || !response?.data) {
      return
    }
    navigate(
      response.data.stand_without_support?.responseValue === 'no'
        ? `${paths.HOME_AUTHED}?checkBalance=true`
        : `${paths.ASSESSMENT_BALANCE}?retake=true`
    )
  }

  if (isCheckinSubmitted) {
    return (
      <ModalConfirm
        {...props}
        title="Thank you!"
        confirmText="Next"
        hideCancel
        size="small"
        onConfirm={handleSuccess}>
        <Text align="left" element="p">
          Thank you for answering those questions! We hope you’ve been enjoying being a member of
          Bold’s Balance and Stability Program. Keep up the strong work– you’re almost done!
        </Text>
      </ModalConfirm>
    )
  }

  return (
    <Modal {...props} className={classNames('EnterpriseOctopusFullDoseCheckinModal', className)}>
      <ModalBox title="Balance and stability program check-in">
        <Container flush size="small">
          <CheckinSingleForm checkinTracker={checkinTracker} onSuccess={submitCheckin} />
        </Container>
      </ModalBox>
    </Modal>
  )
}
