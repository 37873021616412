import { DateTime } from 'luxon'
import React from 'react'
import Row from 'components/Row'
import styles from './AdminLessonStreamsWeek.module.scss'
import AdminLessonStreamsDay from '../AdminLessonStreamsDay'

export default function AdminLessonStreamsWeek({
  lessonStreams,
  onUpdate: handleUpdate,
  startOfWeek,
}) {
  const dateTimesOfWeek = []
  let index = 0
  while (index < 7) {
    dateTimesOfWeek.push(startOfWeek.plus({ days: index }))
    index++
  }

  const daysOfWeekView: any = {}
  lessonStreams.forEach((stream: unknown) => {
    const streamStartDayOfWeek = DateTime.fromISO(stream.scheduledStartTs).weekdayShort
    if (daysOfWeekView[streamStartDayOfWeek]) {
      daysOfWeekView[streamStartDayOfWeek].push(stream)
    } else {
      daysOfWeekView[streamStartDayOfWeek] = [stream]
    }
  })

  return (
    <Row size="small">
      <div className={styles.days}>
        {dateTimesOfWeek.map((dateTime, index_) => (
          <AdminLessonStreamsDay
            key={index_}
            scheduleForDay={daysOfWeekView[dateTime.weekdayShort] || []}
            dateTime={dateTime}
            onUpdate={handleUpdate}
          />
        ))}
      </div>
    </Row>
  )
}
