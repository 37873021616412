import React from 'react'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import ButtonWithModal, { type ButtonWithModalProps } from 'components/ButtonWithModal'
import CheckinSingleForm from 'components/CheckinSingleForm'
import Container from 'components/Container'
import ModalBox from 'components/ModalBox'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import { useGlobalContext } from 'contexts/GlobalContext'
import LessonBox from 'features/lesson/components/LessonBox'
import * as checkinApiRequest from 'libs/checkin-api-request'
import type { Lesson } from 'libs/lesson'
import * as lessonApiRequest from 'libs/lesson-api-request'
import * as notifications from 'libs/notifications'
import * as url from 'libs/url'
import CheckinTracker from 'libs/checkins/checkin-tracker'

type Props = {
  lesson: Lesson
  onFeedbackSuccess?: () => void
  // eslint-disable-next-line no-unused-vars
  onSkip?: (lessonId: string) => void
  sessionId: string
  userExerciseProgramId?: string
} & Partial<ButtonWithModalProps>

export default function ProgramLessonSkipButton({
  lesson,
  onFeedbackSuccess,
  onSkip,
  sessionId,
  userExerciseProgramId,
}: Props) {
  const globalContext = useGlobalContext()
  const [checkinTracker, setCheckinTracker] = React.useState<CheckinTracker>(null)
  // to trigger the next class if exiting modal during or at end of feedback (after confirming Skip)'
  const [isSkipConfirmed, setIsSkipConfirmed] = React.useState(false)
  const [showConfirmation, setShowConfirmation] = React.useState(false)
  const [status, setStatus] = React.useState<'confirmation' | 'feedback'>('confirmation')

  const { skipModal } = url.getQueryString()
  const [showModal, setShowModal] = React.useState(skipModal === 'true' ?? false)

  function trackEvent(eventName: string) {
    globalContext.analytics?.trackEvent(eventName, {
      classType1: lesson.classType?.[0],
      classType2: lesson.classType?.[1],
      isProgramLesson: true,
      lessonName: lesson?.videoTitle,
      lessonType: lesson?.type,
      lessonUrl: lesson?.videoLink,
      trainerName: lesson?.instructorName,
      lessonId: lesson?.id,
    })
  }

  if (skipModal) {
    trackEvent(events.SKIP_LESSON_MODAL_LOCKED)
    url.setUrlWithoutQueryStringKeyValue('skipModal')
  }

  React.useEffect(() => {
    async function getCheckin() {
      const feedbackCheckinResponse = await checkinApiRequest
        .getUserCheckin({
          type: 'after_class',
          code: 'skip_class_feedback',
          activeOnly: true,
          createIfNotExists: true,
          objectId: lesson.id,
        })
        .catch((error: any) => {
          globalContext.analytics?.trackEvent(events.FAILED_CHECKIN_CALL, {
            error,
            from: 'skip_class_feedback',
            type: 'after_class',
          })
          notifications.notifyError()
        })

      if (feedbackCheckinResponse.data) {
        setCheckinTracker(
          new CheckinTracker(
            globalContext.user?.id,
            feedbackCheckinResponse.data,
            globalContext.analytics
          )
        )
      }
    }
    if (lesson && isSkipConfirmed) {
      getCheckin()
    }

    return () => {
      // Optionally reset checkinTracker when the component unmounts or when switching lessons
      setCheckinTracker(null)
    }
  }, [globalContext.analytics, globalContext.user?.id, isSkipConfirmed, lesson])

  async function updateStatusforLesson() {
    const response = await lessonApiRequest.updateLesson(
      {
        sessionId,
        skipped: true,
      },
      {
        lessonId: lesson.id,
        userExerciseProgramId,
      }
    )
    return response
  }

  function handleConfirmSkip() {
    setIsSkipConfirmed(true)
    // if (onSkip) onSkip(lesson.id)
  }

  async function handleConfirmation() {
    trackEvent(events.SKIP_LESSON_CONFIRM)
    setStatus('feedback')
    await updateStatusforLesson()
    handleConfirmSkip()
    notifications.notifySuccess(
      `${lesson.videoTitle} skipped! The next class in your program is ready for you.`
    )
  }

  async function handleClick() {
    trackEvent(events.SKIP_LESSON_BUTTON)
    setShowModal(true)
  }

  function closeModal() {
    setShowModal(false)
  }

  function handleConfirmCancel() {
    trackEvent(events.SKIP_LESSON_CANCEL)
    closeModal()
    setIsSkipConfirmed(false)
  }

  function handleSuccess() {
    trackEvent(events.SKIP_LESSON_COMPLETE_FEEDBACK)
    setShowConfirmation(true)
  }

  function onClose() {
    if (status === 'feedback' && !showConfirmation) {
      trackEvent(events.SKIP_LESSON_CANCEL_FEEDBACK)
      checkinApiRequest.markUserCheckinAsDismissed(checkinTracker.checkinId)
    }

    if (isSkipConfirmed) {
      if (onFeedbackSuccess) onFeedbackSuccess()
      setIsSkipConfirmed(false)
      setShowConfirmation(false)
      setStatus('confirmation')
      if (onSkip) onSkip(lesson.id)
    } else {
      setIsSkipConfirmed(false)
      setShowConfirmation(false)
      setStatus('confirmation')
    }
  }

  return (
    <ButtonWithModal
      color="white"
      modal={
        <>
          {showConfirmation ? (
            <ModalBox
              title="Thank you for sharing your feedback!"
              subtext="Your responses will help us be able to better suggest the classes you’ll see in the future."
            />
          ) : (
            <>
              {status === 'confirmation' && (
                <ModalBox title="Do you want to skip this class?">
                  <Text element="p">
                    This will take you to the next class in your program. You can come back to this
                    class later if you’d like.
                  </Text>
                  <Buttons align="center" equal>
                    <Button color="white" onClick={handleConfirmCancel}>
                      No, nevermind
                    </Button>
                    <Button onClick={handleConfirmation}>Yes, skip class</Button>
                  </Buttons>
                </ModalBox>
              )}
              {status === 'feedback' && (
                <ModalBox>
                  <Container flush size="small">
                    <Row size="small">
                      <LessonBox data={lesson} size="small" />
                    </Row>
                    <CheckinSingleForm checkinTracker={checkinTracker} onSuccess={handleSuccess} />
                  </Container>
                </ModalBox>
              )}
            </>
          )}
        </>
      }
      showModal={showModal}
      size="small"
      onClick={handleClick}
      onClose={onClose}>
      Skip class
    </ButtonWithModal>
  )
}
