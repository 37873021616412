import _ from 'lodash'
import pluralize from 'pluralize'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Carousel from 'components/Carousel'
import Copy from 'components/Copy'
import Heading2 from 'components/Heading2'
import Progress from 'components/Progress'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import type { Challenge, UserChallenge } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as challengeApiRequest from 'libs/challenge-api-request'
import * as notifications from 'libs/notifications'
import * as user from 'libs/user'
import styles from './Challenges.module.scss'

type ChallengesBoxProps = {
  children?: React.ReactNode
  description?: string
  disclaimer?: string
  footer?: React.ReactNode
  title: string
}

function ChallengesBox({
  children,
  description,
  disclaimer,
  footer,
  title,
  ...props
}: ChallengesBoxProps) {
  return (
    <Box {...props} color="white" className={styles.box}>
      <div className={styles['box--body']}>
        <Heading2 level={3} levelStyle={5}>
          {title}
        </Heading2>
        {
          //(description || disclaimer)
          description && (
            <Copy size="small">
              {description && (
                <Text element="p" lines={3}>
                  {description}
                </Text>
              )}
              {/* {disclaimer && (
              <Text element="p">
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: disclaimer }}
                />
              </Text>
            )} */}
            </Copy>
          )
        }
      </div>
      {children}
      {footer}
    </Box>
  )
}

export default function Challenges() {
  const globalContext = useGlobalContext()
  const [challenges, setChallenges] = React.useState<Challenge[] | []>([])
  const [challengesActive, setChallengesActive] = React.useState<UserChallenge[] | []>([])
  const [challengesExpired, setChallengesExpired] = React.useState<UserChallenge[] | []>([])
  const [isJoiningChallenge, setIsJoiningChallenge] = React.useState(false)

  const challengeType = 'oneoff'

  React.useEffect(() => {
    async function getData() {
      if (user.isEnterpriseOctopusUser(globalContext.user)) return

      const challengesResponse = await challengeApiRequest.getActiveChallenges({
        type: challengeType,
      })
      setChallenges(challengesResponse.data)

      const challengesActiveResponse = await challengeApiRequest.getUserChallenges({
        status: 'active',
        isChallengeActive: true,
        type: challengeType,
      })
      setChallengesActive(challengesActiveResponse.data)

      const challengesExpiredResponse = await challengeApiRequest.getUserChallenges({
        status: 'expired',
        type: challengeType,
      })
      setChallengesExpired(challengesExpiredResponse.data)
    }
    getData()
  }, [globalContext.user])

  async function handleJoinChallenge(challengeId, challengeName) {
    try {
      // Prevent double click
      // https://stackoverflow.com/questions/35315872/reactjs-prevent-multiple-times-button-press
      if (isJoiningChallenge) {
        return null
      }

      setIsJoiningChallenge(true)
      await challengeApiRequest.joinChallenge(challengeId)
      notifications.notifySuccess('Thanks for joining the challenge!')

      const response = await challengeApiRequest.getUserChallenges(challengeType)
      setChallengesActive(response.data)

      setIsJoiningChallenge(false)

      globalContext.analytics?.trackEvent(events.JOIN_CHALLENGE, {
        challengeId,
        challengeName,
        success: true,
      })
    } catch {
      setIsJoiningChallenge(false)
      notifications.notifyError('Something went wrong joining that challenge. Please try again.')
      globalContext.analytics?.trackEvent(events.JOIN_CHALLENGE, {
        challengeId,
        challengeName,
        success: false,
      })
    }
  }

  const skillBuildingTag = 'skill_building'

  const getChallengeTo = (challenge: Challenge) =>
    challenge.collectionId
      ? `${paths.PLAYLIST}${challenge.collectionId}`
      : challenge.newProgramId
        ? paths.PROGRAM
        : paths.EXPLORE

  // $View-padding
  //const carouselOffset = 40

  return (
    <div className="Challenges">
      {_.isEmpty(challenges) ||
      (_.isEmpty(challenges.filter((item) => item.tag !== skillBuildingTag)) &&
        _.isEmpty(challengesActive) &&
        _.isEmpty(challengesExpired)) ? (
        <Text element="p" flush size="large">
          No challenges are currently happening, check back soon!
        </Text>
      ) : (
        <Carousel
          breakpoints={{
            568: {
              slidesPerView: 2,
            },
            769: {
              slidesPerView: 3,
            },
          }}
          loop={false}
          navigationProps={{
            background: true,
          }}
          showNavigation
          //slidesOffsetBefore={carouselOffset}
          //slidesOffsetAfter={carouselOffset}
          slidesPerView={1}
          spaceBetween={20}
          className={styles.carousel}>
          {challenges?.map((challenge) => {
            if (
              challengesActive.some(
                (challengeActive) => challengeActive.challengeId === challenge.id
              ) ||
              challenge.tag === skillBuildingTag
            ) {
              return null
            }

            return (
              <ChallengesBox
                title={challenge.name}
                description={challenge.description}
                disclaimer={challenge.disclaimer}
                footer={
                  <Button
                    color="yellow"
                    loading={isJoiningChallenge}
                    onClick={() => handleJoinChallenge(challenge.id, challenge.name)}>
                    Join challenge
                  </Button>
                }
                key={challenge.id}
              />
            )
          })}

          {challengesActive.map((item) => (
            <ChallengesBox
              title={`
                ${item.challenge.name}
                ${item.progress > item.challenge.threshold ? ' (Completed)' : ''}
              `}
              description={item.challenge.description}
              disclaimer={item.challenge.disclaimer}
              footer={
                <>
                  <Row size="xxsmall">
                    <Button to={getChallengeTo(item.challenge)} color="yellow">
                      View classes
                    </Button>
                  </Row>

                  <Row size="xxsmall">
                    <Text weight="medium">
                      {Math.round(item.progress)} of{' '}
                      {pluralize(
                        item.challenge.unit === 'unit' ? 'lesson' : item.challenge.unit,
                        item.challenge.threshold,
                        true
                      )}
                    </Text>
                  </Row>

                  <Progress
                    value={item.progress}
                    total={item.challenge.threshold}
                    color="green"
                    size="small"
                  />
                </>
              }
              key={item.id}
            />
          ))}

          {challengesExpired.map((item) => {
            const isCompleted = item.progress >= item.challenge.threshold

            return (
              <ChallengesBox
                title={`
                  ${item.challenge.name}
                  ${isCompleted ? ' (Completed)' : ''}
                `}
                description={item.challenge.description}
                disclaimer={item.challenge.disclaimer}
                footer={
                  <>
                    <Row size="xxsmall">
                      <Text weight="medium">
                        {Math.round(item.progress)} of{' '}
                        {pluralize(
                          item.challenge.unit === 'unit' ? 'lesson' : item.challenge.unit,
                          item.challenge.threshold,
                          true
                        )}
                      </Text>
                    </Row>
                    <Progress
                      value={item.progress}
                      total={item.challenge.threshold}
                      color="green"
                      size="small"
                    />
                  </>
                }
                key={item.id}>
                {isCompleted ? (
                  <Text element="p" flush weight="medium">
                    Nice work, you completed the {item.challenge.name} and you were entered into a
                    raffle to win Bold swag!
                  </Text>
                ) : (
                  <Button to={getChallengeTo(item.challenge)} color="yellow">
                    View classes
                  </Button>
                )}
              </ChallengesBox>
            )
          })}
        </Carousel>
      )}
    </div>
  )
}
