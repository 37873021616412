import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import ButtonWithModal from 'components/ButtonWithModal'
import Container from 'components/Container'
import Heading2 from 'components/Heading2'
import Image from 'components/Image'
import LessonTrainer from 'components/LessonTrainer'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import LessonFavoriteButton from 'features/lesson/components/LessonFavoriteButton'
import GoalAndRemindersModalBox from 'features/program/components/GoalAndRemindersModalBox'
// TODO: make relative
import ProgramPostLessonFeedback from 'features/program/components/Program/components/ProgramPostLessonFeedback'
import ProgramGoal from 'features/program/components/ProgramGoal'
import * as date from 'libs/date'
import type { Lesson } from 'libs/lesson'
import { useUserLessonsFavorite } from 'libs/lessons-query'
import { useActiveProgramProgress } from 'libs/program-query'
import { useUserGoal } from 'libs/user-query'
import styles from './ProgramHeaderPostLesson.module.scss'

type Props = {
  data: Lesson
  lessonNext: Lesson
  onLessonNextClick: () => void
} & Component

export default function ProgramHeaderPostLesson({
  className,
  data,
  lessonNext,
  onLessonNextClick,
}: Props) {
  const globalContext = useGlobalContext()
  const { data: userGoalData } = useUserGoal()
  const { data: programProgressData } = useActiveProgramProgress()
  const programProgress = programProgressData?.data
  const numberClassesTaken = programProgress?.numClassesCompleted
  const { data: lessonsFavoriteData } = useUserLessonsFavorite()
  const [nudgeGoalsAndRemindersModal, setNudgeGoalsAndRemindersModal] = React.useState(false)
  const [showGoalAndRemindersModal, setShowGoalAndRemindersModal] = React.useState(false)

  const hasReminders =
    userGoalData?.data?.reminderDays && userGoalData?.data?.reminderDays[0] !== 'None'

  React.useEffect(() => {
    setNudgeGoalsAndRemindersModal(
      !hasReminders && (numberClassesTaken === 1 || numberClassesTaken === 2)
    )
  }, [hasReminders, numberClassesTaken])

  function getNextRemindersDate() {
    if (!hasReminders) return ''

    const { reminderDays } = userGoalData.data

    const reminderDaysAndIndices = Object.fromEntries(
      reminderDays.map((day) => [date.DAYS_OF_WEEK.indexOf(day), day])
    )
    const todayOfWeek = date.getDayOfWeek(new Date())
    const todayOfWeekIndex = date.DAYS_OF_WEEK.indexOf(todayOfWeek)
    let nextPreferredDay = null

    for (const index of Object.keys(reminderDaysAndIndices).sort()) {
      if (todayOfWeekIndex < index) {
        nextPreferredDay = reminderDaysAndIndices[index]
        break
      }
    }

    // loop around to beginning of (sorted) preferred days if needed
    if (nextPreferredDay === null) {
      nextPreferredDay = Object.entries(reminderDaysAndIndices)
        .sort((a, b) => Number(a[0]) - Number(b[0]))
        .map((element) => element[1])[0]
    }

    const nextDate = date.getDayOfWeekNext(nextPreferredDay)

    return date.formatDisplayWithDayOfWeek(nextDate)
  }

  function handleSuccess() {
    setShowGoalAndRemindersModal(false)
    globalContext.analytics?.trackEvent('Goal and reminders: edited reminders', {
      from: 'post-lesson program header',
    })
  }

  // TODO: should this be added to Lesson
  const isFavorite = _.some(lessonsFavoriteData?.data, (item) => item.id === data.id)

  if (_.isEmpty(data)) return null

  return (
    <div className={classNames('ProgramHeaderPostLesson', styles.this, className)}>
      <Container size="xxlarge">
        <div className={styles.grid}>
          <figure className={styles.lesson}>
            <div className={styles['lesson--header']}>
              <div className={styles['lesson--image-wrap']}>
                <Image src={data.videoThumbnail} full className={styles['lesson--image']} />
              </div>
              <Text color="white" className={styles['lesson--image-overlay']}>
                <Text weight="semibold">Class completed</Text>
                <Text element="figcaption">
                  <Row size="xxxsmall">
                    <Text element="h3" size="large" weight="semibold">
                      {data.videoTitle}
                    </Text>
                  </Row>
                  <Row size="xxxsmall">
                    <Text className={styles['lesson--meta']}>{data.instructorName}</Text>
                  </Row>
                </Text>
              </Text>
            </div>
            <div className={styles['lesson--body']}>
              <Row flush={isFavorite} size="xsmall">
                <ProgramPostLessonFeedback data={data} />
              </Row>
              {!isFavorite && <LessonFavoriteButton full lesson={data} lessonId={data.id} />}
            </div>
          </figure>
          <div>
            <Row size="xmedium">
              <ProgramGoal confetti nudgeGoalModal={nudgeGoalsAndRemindersModal} />
            </Row>
            <Box color="white">
              <Heading2 levelStyle={5}>Up next</Heading2>
              <Row flush={!hasReminders} size="smallx">
                <Row size="smallx">
                  <MediaObject
                    figure={<LessonTrainer data={lessonNext} showName={false} />}
                    size="xsmall">
                    <Row size="xxxsmall">
                      <Text element="h3" weight="medium">
                        {lessonNext.videoTitle}
                      </Text>
                    </Row>
                    <Text element="p" flush>
                      {lessonNext.instructions}
                    </Text>
                  </MediaObject>
                </Row>
                <Button color="yellow" onClick={onLessonNextClick}>
                  Start class
                </Button>
              </Row>
              {hasReminders && (
                <Box color="grey" size="xsmall">
                  <MediaObject
                    figure={
                      <ButtonWithModal
                        level="text"
                        modal={
                          <GoalAndRemindersModalBox status="reminders" onSuccess={handleSuccess} />
                        }
                        showModal={showGoalAndRemindersModal}>
                        Edit
                      </ButtonWithModal>
                    }
                    center
                    figurePosition="right">
                    <Text copy weight="medium">
                      Reminder scheduled for {getNextRemindersDate()}
                    </Text>
                  </MediaObject>
                </Box>
              )}
            </Box>
          </div>
        </div>
      </Container>
    </div>
  )
}
