import classNames from 'classnames'
import React from 'react'
import Button from 'components/Button'
import * as events from 'constants/events'
import statusCodes from 'constants/status-codes'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as api from 'libs/api'
import * as notifications from 'libs/notifications'
import * as subscriptionApiRequest from 'libs/subscription-api-request'

type Props = {
  children: React.ReactNode
} & Component

export default function SubscriptionReactivateButton({
  children = 'Reactivate my subscription',
  className,
  ...props
}: Props) {
  const globalContext = useGlobalContext()

  async function handleClick() {
    if (!globalContext.user) return

    globalContext.analytics?.trackEvent(events.SUBSCRIPTION_REACTIVATE_BUTTON_CLICK)

    const response = await subscriptionApiRequest
      .updateSubscription(globalContext.user.subscription?.id, {
        action: api.ACTION_REACTIVATE_SUBSCRIPTION,
      })
      .catch((error) => {
        console.error(error)
      })

    if (response.code === statusCodes.POST_SUCCESS) {
      notifications.notifySuccess('You’ve successfully reactivated your subscription!')

      globalContext.analytics?.trackEvent(events.REACTIVATE_SUBSCRIPTION)

      globalContext.update({
        user: {
          ...globalContext.user,
          subscription: response.data.subscription,
        },
      })
    } else {
      notifications.notifyError('Something went wrong. Please try again later.')
    }
  }

  if (!globalContext.user?.subscription?.cancel_at_period_end) return null

  return (
    <Button
      {...props}
      className={classNames('SubscriptionReactivateButton', className)}
      onClick={handleClick}>
      {children}
    </Button>
  )
}
