import classNames from 'classnames'
import React from 'react'
import Box from 'components/Box'
import Heading2 from 'components/Heading2'
import Modal, { type ModalProps } from 'components/Modal'
import ModalBox from 'components/ModalBox'
import Row from 'components/Row'
import Text from 'components/Text'

type Props = {
  children: React.ReactNode
  image: React.ReactNode
  subtext: string
  title: string
} & ModalProps

export default function ProgramGoalStreakModal({
  children,
  className,
  image,
  subtext,
  title,
  ...props
}: Props) {
  return (
    <Modal {...props} className={classNames('ProgramGoalStreakModal', className)}>
      <ModalBox>
        <Row size="xsmall">{image}</Row>
        <Heading2 levelStyle={4}>{title}</Heading2>
        <Text element="p">{subtext}</Text>
        <Box color="grey">{children}</Box>
      </ModalBox>
    </Modal>
  )
}
