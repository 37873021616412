import { navigate } from 'gatsby'
import React from 'react'
import Button from 'components/Button'
import ButtonsFloating from 'components/ButtonsFloating'
import Row from 'components/Row'
import Text, { type TextProps } from 'components/Text'
import paths from 'constants/paths'
import { useGlobalContext } from 'contexts/GlobalContext'
import OnboardingBox from 'features/onboarding/components/OnboardingBox'
import OnboardingLayout from 'features/onboarding/components/OnboardingLayout'
import OnboardingView from 'features/onboarding/components/OnboardingView'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as user from 'libs/user'
import styles from './OnboardingIntro.module.scss'

export default function OnboardingIntro() {
  useEventPageVisited('Onboarding Intro')
  const globalContext = useGlobalContext()
  const isSpecial = user.hasHomeSpecial()

  const textPurpleProps: Partial<TextProps> = {
    color: 'purple',
    weight: 'medium',
  }

  const steps = [
    {
      copy: (
        <>
          First, we’ll ask you about your <Text {...textPurpleProps}>preferences</Text>
        </>
      ),
      icon: 'user',
    },
    {
      copy: (
        <>
          Next, we’ll ask you about your <Text {...textPurpleProps}>background</Text>
        </>
      ),
      icon: 'clock',
    },
    {
      copy: (
        <>
          Last, we’ll ask you about a few <Text {...textPurpleProps}>movements</Text>
        </>
      ),
      icon: 'walk',
    },
  ]

  return (
    <OnboardingLayout title="Getting started" className="OnboardingIntro">
      <OnboardingView
        title={
          isSpecial ? (
            `While we check your eligibility, let's personalize your program`
          ) : globalContext?.user?.firstName ? (
            <>
              Let’s create a personalized program for you,{' '}
              <Text color="purple">{globalContext.user.firstName}</Text>!
            </>
          ) : (
            'Let’s create a personalized program for you!'
          )
        }>
        <Text element="p">To create a program for you we will ask you a series of questions.</Text>
        <Row size="smallx">
          <div className={styles.steps}>
            {steps.map((item, index) => (
              <OnboardingBox icon={item.icon} key={index} className={styles['steps--item']}>
                {item.copy}
              </OnboardingBox>
            ))}
          </div>
        </Row>
        <Text element="p">
          Your responses will allow us to create a program personalized to help you reach your
          exercise goals.
        </Text>
        <ButtonsFloating>
          <Button
            data-test-id="onboarding-intro--button"
            full
            size="large"
            onClick={() => navigate(paths.ONBOARDING_QUESTIONS, { replace: true })}>
            Let’s get started!
          </Button>
        </ButtonsFloating>
      </OnboardingView>
    </OnboardingLayout>
  )
}
