import React from 'react'

const ExploreFiltersContext = React.createContext({})
const { Consumer, Provider } = ExploreFiltersContext

type ProviderProps = {
  children: React.ReactNode
}

function ExploreFiltersContextProvider(props: ProviderProps) {
  const [open, setOpen] = React.useState('')

  function update(value: string) {
    setOpen(value)
  }

  return (
    <Provider
      {...props}
      value={{
        open,
        update,
      }}
    />
  )
}

type ConsumerProps = {
  // TODO
  // eslint-disable-next-line no-unused-vars
  children: (store: {}) => React.ReactNode
}

function ExploreFiltersContextConsumer({ children }: ConsumerProps) {
  return <Consumer>{(store) => children(store)}</Consumer>
}

const useExploreFiltersContext = () => React.useContext(ExploreFiltersContext)

export default ExploreFiltersContext
export { ExploreFiltersContextConsumer, ExploreFiltersContextProvider, useExploreFiltersContext }
