import classNames from 'classnames'
import { format } from 'date-fns'
import _ from 'lodash'
import { DateTime } from 'luxon'
import React from 'react'
import Row from 'components/Row'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import * as date from 'libs/date'
import { useProgramCurrentWeek } from 'libs/program-query'
import * as user from 'libs/user'
import styles from './ProgramGoalWeek.module.scss'
import { ReactComponent as LightningSvg } from './images/lightning.svg'

type Props = {
  highlight?: boolean
} & Component

export default function ProgramGoalWeek({ className, highlight }: Props) {
  const { data: programCurrentWeekData } = useProgramCurrentWeek()
  const sundayThisWeek = DateTime.now().setZone(date.TIMEZONE).startOf('week').minus({ days: 1 }) // week starts on Monday in luxon but we start on Sunday

  const lessons = _.get(programCurrentWeekData, 'data.recentGeneralSessions', [])
  const lessonsThisWeek = user.getUniqueDaysActiveThisWeek(lessons)
  const lessonsDaysActive = lessonsThisWeek.map((item) => format(item.zonedItemDate, 'EEEE'))

  return (
    <Text align="center" className={classNames('ProgramGoalWeek', styles.this, className)}>
      {date.DAYS_OF_WEEK_XXSMALL.map((item: string, index: number) => {
        const date2 = sundayThisWeek.plus({ days: index })
        const todayOfWeek = date.getDayOfWeek(new Date())
        const todayOfWeekIndex = date.DAYS_OF_WEEK.indexOf(todayOfWeek)
        const dayOfWeek = date2.weekdayLong
        const dayOfWeekIndex = date.DAYS_OF_WEEK.indexOf(dayOfWeek)

        const isPast = todayOfWeekIndex > dayOfWeekIndex
        const isToday = todayOfWeekIndex === dayOfWeekIndex
        const isFuture = todayOfWeekIndex < dayOfWeekIndex
        const isActive = lessonsDaysActive?.includes(dayOfWeek)

        return (
          <Text
            color={isActive ? 'yellow500' : 'grey'}
            element="div"
            key={index}
            weight="medium"
            className={classNames(
              styles.day,
              isPast && styles['day---past'],
              isToday && styles['day---today'],
              isFuture && styles['day---future'],
              isActive && styles['day---active'],
              isActive && 'ProgramGoalWeek--day---active'
            )}>
            <Row size="xxxsmall" aria-hidden>
              {isActive ? (
                <LightningSvg className={highlight ? styles['day--icon'] : undefined} />
              ) : (
                <div className={styles['day--marker']} />
              )}
            </Row>
            <span>{item}</span>
          </Text>
        )
      })}
    </Text>
  )
}
