import classNames from 'classnames'
import { Form, Formik } from 'formik'
import React from 'react'
import Media from 'react-media'
import * as Yup from 'yup'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import ButtonWithModal from 'components/ButtonWithModal'
import Container from 'components/Container'
import Field from 'components/Field'
import Field2 from 'components/Field2'
import FieldDate from 'components/FieldDate'
import FieldEmail from 'components/FieldEmail'
import FieldFirstName from 'components/FieldFirstName'
import FieldGender from 'components/FieldGender'
import FieldLastName from 'components/FieldLastName'
import FieldPhoneNumber from 'components/FieldPhoneNumber'
import FieldPostalCode from 'components/FieldPostalCode'
import Fields from 'components/Fields'
import FieldSmsConsentCheckbox from 'components/FieldSmsConsentCheckbox'
import FormValidationNotification from 'components/FormValidationNotification'
import Label from 'components/Label'
import Row from 'components/Row'
import Text from 'components/Text'
import emails from 'constants/emails'
import * as events from 'constants/events'
import statusCodes from 'constants/status-codes'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import ProgramChangeModal from 'features/program/components/ProgramChangeModal'
import * as logrocket from 'libs/logrocket'
import * as notifications from 'libs/notifications'
import * as user from 'libs/user'
import * as userApiRequest from 'libs/user-api-request'
import * as validations from 'libs/validations'
import constants from 'styles/constants.module.scss'

type Props = {
  buttonsProps: {}
  onSuccess: () => void
} & Component

export default function ProfileForm({ buttonsProps = {}, className, onSuccess }: Props) {
  const globalContext = useGlobalContext()
  const [handleChangeProgramSubmitLoading, setHandleChangeProgramSubmitLoading] =
    React.useState(false)
  const [showProgramChangeModal, setShowProgramChangeModal] = React.useState(false)

  async function submit(values, formikActions) {
    function handleError(error) {
      console.error(error)
      const message =
        error.statusCode === statusCodes.NO_OP
          ? 'This email is already in use. Please enter a different email or call 833-701-1545 (toll-free).'
          : undefined
      notifications.notifyError(message)
      formikActions.setSubmitting(false)
    }

    const response = await userApiRequest
      .updateUser({
        // updates SMS consent if phone number provided
        ...values,
      })
      .catch((error) => {
        handleError(error)
      })

    if (response.statusCode !== statusCodes.POST_SUCCESS) {
      handleError(response)
      formikActions.setSubmitting(false)
      return
    }

    formikActions.setSubmitting(false)
    await globalContext.update({ user: response.data })
    await globalContext.updateUser()

    if (onSuccess) onSuccess()
  }

  function handleSubmit(values, formikActions) {
    // returning the promise here is important because formik relies on this to update isSubmitting properly which we rely on to ensure we don't double-fire OB complete events
    // due to https://github.com/formium/formik/issues/1730
    return submit(values, formikActions)
  }

  const currentExerciseProgram = globalContext.user.currentExerciseProgram

  async function handleChangeProgramSubmit(difficultyFeedback) {
    if (!globalContext.user) return

    setShowProgramChangeModal(true)

    function handleError() {
      globalContext.analytics?.trackEvent(events.SWITCH_USER_EXERCISE_PROGRAM, {
        success: 'false',
        reason: `previous_program_too_${difficultyFeedback}`,
        previousProgram: currentExerciseProgram,
        switchInitiatedFrom: 'settings_page',
      })
      notifications.notifyError(
        `Sorry, we couldn’t change your program right now. Please contact us at ${emails.DEFAULT}.`
      )
    }

    try {
      if (handleChangeProgramSubmitLoading) {
        // Prevent double-click: https://stackoverflow.com/questions/35315872/reactjs-prevent-multiple-times-button-press
        return null
      }
      setHandleChangeProgramSubmitLoading(true)
      const previousProgram = currentExerciseProgram

      const response = await userApiRequest.switchUserExerciseProgram(difficultyFeedback)

      if (response.statusCode !== 200) {
        handleError()
        setHandleChangeProgramSubmitLoading(false)
        setShowProgramChangeModal(false)
        return
      }

      globalContext.updateUser()
      notifications.notifySuccess('Program updated!')

      globalContext.analytics?.trackEvent(events.SWITCH_USER_EXERCISE_PROGRAM, {
        success: 'true',
        reason: `previous_program_too_${difficultyFeedback}`,
        previousProgram,
        newProgram: response?.data?.title,
        switchInitiatedFrom: 'settings_page',
      })
    } catch {
      handleError()
    }
    setHandleChangeProgramSubmitLoading(false)
    setShowProgramChangeModal(false)
  }

  const fieldProps = {
    ...logrocket.INPUT_PHI_PROPS,
  }

  const fieldPostalCode = <FieldPostalCode {...fieldProps} />

  return (
    <Formik
      enableReinitialize
      initialValues={{
        dateOfBirth: globalContext.user.dateOfBirth ? '******' : '',
        email: globalContext.user.email ? '******' : '',
        firstName: globalContext.user.firstName ? '******' : '',
        gender: '',
        healthCareProvider: globalContext.user.healthCareProvider ? '******' : '',
        lastName: globalContext.user.lastName ? '******' : '',
        phoneNumber: globalContext.user.phoneNumber ? '******' : '',
        postalCode: globalContext.user.postalCode ? '******' : '',
        smsConsent: globalContext.user.smsConsent,
      }}
      validationSchema={Yup.object({
        dateOfBirth: validations.DATE_OF_BIRTH_OPTIONAL,
        email: validations.EMAIL,
        firstName: validations.NAME,
        healthCareProvider: validations.HEALTH_PLAN,
        lastName: validations.NAME,
        phoneNumber: validations.PHONE_NUMBER_OPTIONAL,
        postalCode: validations.POSTAL_CODE_OPTIONAL,
      })}
      className={classNames('ProfileForm', className)}
      onSubmit={handleSubmit}>
      {(formikProps) => (
        <Form>
          <FormValidationNotification />
          <Text element="p">
            Update your information below to verify eligibility for covered services. If you have
            already verified your eligibility, you do not need to enter this information again.{' '}
          </Text>
          <Fields>
            <FieldEmail {...fieldProps} required />
            <FieldPhoneNumber {...fieldProps} optional />
          </Fields>
          <FieldSmsConsentCheckbox disclaimerIsOptional />
          <Fields>
            <FieldFirstName {...fieldProps} required />
            <FieldLastName {...fieldProps} required />
          </Fields>
          <Fields>
            <FieldDate {...fieldProps} label="Date of birth" name="dateOfBirth" />
            <Media query={{ minWidth: constants.GT_MOBILE }}>{fieldPostalCode}</Media>
            <FieldGender />
          </Fields>
          <Media query={{ maxWidth: constants.MOBILE }}>{fieldPostalCode}</Media>
          {!user.isEnterpriseOctopusUser(globalContext.user) && (
            <Field2 {...fieldProps} label="Health insurance plan" name="healthCareProvider" />
          )}
          {!user.isFreeUser(globalContext.user) && (
            <Field>
              <Row flush={!user.canChangeProgram(globalContext.user)} size="xsmall">
                <Label>Program</Label>
                <Text>{globalContext.user.currentExerciseProgramExternalName}</Text>
              </Row>
              {user.canChangeProgram(globalContext.user) && (
                <ButtonWithModal
                  color="purple"
                  level="text"
                  showModal={showProgramChangeModal}
                  size="small"
                  modal={
                    <ProgramChangeModal
                      onSubmit={(difficultyFeedback) =>
                        handleChangeProgramSubmit(difficultyFeedback)
                      }
                    />
                  }
                  modalProps={{
                    size: 'small',
                  }}>
                  <Text size="small">Try a new program</Text>
                </ButtonWithModal>
              )}
            </Field>
          )}
          {user.isEnterpriseOctopusUser(globalContext.user) && (
            <Field2
              label="I consent to receive information about my Bold program via text message"
              name="smsConsent"
              type="checkbox"
            />
          )}
          <Row size="small">
            <Container align={buttonsProps?.align} flush size="xsmall">
              <Buttons {...buttonsProps}>
                <Button disabled={formikProps.isSubmitting} onClick={formikProps.submitForm}>
                  Save
                </Button>
              </Buttons>
            </Container>
          </Row>
          <Text element="p" size="xsmall">
            {user.isEnterpriseOctopusUser(globalContext.user) &&
              'We use information about gender and age to help personalize programs for members. '}
          </Text>
        </Form>
      )}
    </Formik>
  )
}
