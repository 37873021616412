import _ from 'lodash'
import { DateTime } from 'luxon'
import React from 'react'
import Button from 'components/Button'
import Modal from 'components/Modal'
import ModalBox from 'components/ModalBox'
import Text from 'components/Text'
import * as adminApiRequest from 'features/admin/libs/admin-api-request'
import type { LessonStream } from 'libs/lesson'
import * as notifications from 'libs/notifications'

type Props = {
  isOpen: boolean
  onClose: () => void
  onUpdate: () => void
  lessonStream: LessonStream
}

export default function LessonStreamsActionModal({
  lessonStream,
  isOpen,
  onClose,
  onUpdate: handleUpdate,
}: Props) {
  const {
    id,
    streamType,
    zoomMeetingId,
    scheduledStartTs,
    endTs,
    status,
    lesson: { videoTitle, instructorName },
  } = lessonStream

  const isInPast = !_.isNil(endTs) && new Date(endTs) < new Date()
  const isCanceled = status === 'canceled'

  const scheduledDateTime = DateTime.fromISO(scheduledStartTs)

  const handleConvertToRerun = async () => {
    const response = await adminApiRequest.convertLiveLessonStreamToRerun(id)
    if (response.statusCode === 200) {
      notifications.notifySuccess('Converted to Rerun!')
    } else {
      notifications.notifyError(
        'Unable to convert to rerun! Try deleting this and creating a new rerun'
      )
    }
    if (handleUpdate) handleUpdate()
  }

  const handleCancelLessonStream = async () => {
    const response = await adminApiRequest.cancelLessonStream(id)
    if (response.statusCode === 200) {
      notifications.notifySuccess('Canceled!')
    } else {
      notifications.notifyError('Unable to cancel!')
    }
    if (handleUpdate) handleUpdate()
  }

  return (
    <Modal size="xxlarge" isOpen={isOpen} onRequestClose={onClose}>
      <ModalBox size="small">
        <Text element="p" size="xlarge" weight="semibold">
          {videoTitle}
        </Text>
        <Text element="p" weight="medium">
          {instructorName}
        </Text>
        <Text element="p">
          {scheduledDateTime.toLocaleString(DateTime.TIME_SIMPLE)} ({streamType})
        </Text>
        {!isCanceled && !isInPast && (
          <>
            {streamType === 'zoom' && (
              <>
                <Text element="p" weight="medium">
                  Zoom Meeting ID: {zoomMeetingId}
                </Text>
                <Button onClick={handleConvertToRerun}>Switch to Rerun</Button>
              </>
            )}
            <Button onClick={handleCancelLessonStream}>Cancel</Button>
            <Text element="p">Note: Action(s) above cannot be undone!</Text>
          </>
        )}
        {isCanceled && <Text>CANCELED</Text>}
      </ModalBox>
    </Modal>
  )
}
