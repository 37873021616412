import { Form, Formik } from 'formik'
import _ from 'lodash'
import React from 'react'
import Toggle from 'react-toggle'
import Button from 'components/Button'
import Field2 from 'components/Field2'
import Row from 'components/Row'
import Text from 'components/Text'
import * as adminApiRequest from 'features/admin/libs/admin-api-request'
import * as notifications from 'libs/notifications'
import 'react-toggle/style.css'

type Props = {
  lessonRegimenId: string
}

export default function LessonRegimenStreams({ lessonRegimenId }: Props) {
  const [lessonStreams, setLessonStreams] = React.useState([])

  async function getStreamsForRegimen() {
    const response = await adminApiRequest.getLessonStreamsForLessonRegimen(lessonRegimenId)
    if (response && response.statusCode === 200) {
      setLessonStreams(response.data)
    }
  }

  React.useEffect(() => {
    getStreamsForRegimen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessonRegimenId])

  const handleToggleEligibility = async (lessonStreamId: string, rerunEligible: boolean) => {
    await adminApiRequest.toggleRerunEligibility(lessonStreamId, rerunEligible)
    notifications.notifySuccess('Successfully updated lesson rerun eligibility!')
    getStreamsForRegimen()
  }

  const handleToggleExplorability = async (lessonId: string, isLibraryClass: boolean) => {
    await adminApiRequest.handleToggleExplorability(lessonId, isLibraryClass)
    notifications.notifySuccess('Successfully updated lesson explorability!')
    getStreamsForRegimen()
  }

  const handleChangeLessonUrl = async (values) => {
    await adminApiRequest.updateLessonUrlAndTitle(
      values.lessonId,
      values.videoLink,
      values.originalStreamTs
    )
    notifications.notifySuccess('Successfully updated lesson url!')
    getStreamsForRegimen()
  }

  return (
    <>
      <Row>
        <Text weight="semibold">LIVE CLASSES FOR THIS SCHEDULE</Text>
      </Row>
      <table>
        <thead>
          <tr>
            <th>
              <Text weight="semibold">Lesson</Text>
            </th>
            <th>
              <Text weight="semibold">Date recorded</Text>
            </th>
            <th>
              <Text weight="semibold">Video URL</Text>
            </th>
            <th>
              <Text weight="semibold">Rerun count</Text>
            </th>
            <th>
              <Text weight="semibold">Eligible for reruns?</Text>
            </th>
            <th>
              <Text weight="semibold">Is on explore?</Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {!_.isEmpty(lessonStreams) &&
            lessonStreams.map((lessonStream, index) => (
              <tr key={`${lessonStream.id}-${index}`}>
                <td>
                  {lessonStream.videoTitle} with {lessonStream.instructorName}
                </td>
                <td>{new Date(lessonStream.scheduledStartTs).toLocaleDateString('en-US')}</td>
                <td>
                  <Formik
                    initialValues={{
                      videoLink: lessonStream.videoLink,
                      lessonId: lessonStream.lessonId,
                      videoTitle: lessonStream.videoTitle,
                      scheduledStartTs: lessonStream.scheduledStartTs,
                    }}
                    onSubmit={handleChangeLessonUrl}>
                    {(formikProps) => (
                      <Form>
                        <Field2 name="videoLink" labelAsPlaceholder label="Add URL" flush />
                        <Button
                          disabled={formikProps.isSubmitting}
                          size="xxsmall"
                          weight="normal"
                          type="submit"
                          pill
                          onClick={formikProps.submitForm}>
                          Update URL
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </td>
                <td style={{ textAlign: 'center' }}>{lessonStream.rerunCount || 0}</td>
                <td>
                  <Toggle
                    checked={lessonStream.rerunEligible}
                    icons={false}
                    disabled={lessonStream.scheduledStartTs > new Date() || !lessonStream.videoLink}
                    onChange={() =>
                      handleToggleEligibility(lessonStream.id, !lessonStream.rerunEligible)
                    }
                  />
                </td>
                <td>
                  <Toggle
                    checked={!!lessonStream.exploreStatus}
                    icons={false}
                    disabled={_.isNil(lessonStream.videoLink)}
                    onChange={() =>
                      handleToggleExplorability(lessonStream.lessonId, !lessonStream.exploreStatus)
                    }
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  )
}
