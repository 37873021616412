import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Divider from 'components/Divider'
import Loader from 'components/Loader'
import type { Component } from 'constants/types'
import type { Program, ProgramBlock as ProgramBlockType } from 'libs/program'
import ProgramBlock, { type ProgramBlockProps } from '../ProgramBlock'

type Props = {
  lessonOverride: any
  onLessonSkipped: any
  playSkippedLesson: any
  program: Program
} & Partial<ProgramBlockProps> &
  Component

export default function ProgramBlocks({
  className,
  lessonOverride,
  onLessonSkipped,
  playSkippedLesson,
  program,
  ...props
}: Props) {
  if (_.isEmpty(program)) return null

  const { exerciseBlocks } = program.exerciseProgram

  return (
    <div className={classNames('ProgramBlocks', className)}>
      {_.isEmpty(exerciseBlocks) ? (
        <Loader overlay={false} />
      ) : (
        exerciseBlocks.map((block: ProgramBlockType, index: number) => (
          <React.Fragment key={block.id}>
            <ProgramBlock
              {...props}
              data={block}
              lessonOverride={lessonOverride}
              program={program}
              onLessonSkipped={onLessonSkipped}
              playSkippedLesson={playSkippedLesson}
            />
            {index < _.size(exerciseBlocks) - 1 && <Divider axis="y" size="small" />}
          </React.Fragment>
        ))
      )}
    </div>
  )
}
