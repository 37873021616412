import { Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import Button from 'components/Button'
import Container from 'components/Container'
import Divider from 'components/Divider'
import Field2 from 'components/Field2'
import Heading from 'components/Heading'
import Table from 'components/Table'
import View from 'components/View'
import * as adminApiRequest from 'features/admin/libs/admin-api-request'
import * as enterpriseLib from 'libs/enterprise'
import * as validations from 'libs/validations'

export default function AdminBoldlessEnterpriseMembers() {
  const [stateEnterprises, setStateEnterprises] = React.useState([])
  const [selectedEnterpriseId, setSelectedEnterpriseId] = React.useState('')
  const [boldlessData, setBoldlessData] = React.useState([])

  React.useEffect(() => {
    async function fetchEnterprises() {
      const getEnterprises = await adminApiRequest.getEnterpriseList()
      const fetchedEnterprises = getEnterprises.data
      setStateEnterprises(fetchedEnterprises)
      if (fetchedEnterprises && fetchedEnterprises.length > 0) {
        // set to ochsner by default
        const octopusEnterprise = fetchedEnterprises.find(
          (ent) => ent.internalId === enterpriseLib.OCTOPUS_KEY
        )
        setSelectedEnterpriseId(octopusEnterprise.id)
      }
    }
    fetchEnterprises()
  }, [])

  React.useEffect(() => {
    async function fetchBoldlessEnterpriseMembers() {
      if (selectedEnterpriseId) {
        const boldlessEnterpriseMembers =
          await adminApiRequest.fetchBoldlessEnterpriseMembers(selectedEnterpriseId)
        setBoldlessData(boldlessEnterpriseMembers.data)
      }
    }
    fetchBoldlessEnterpriseMembers()
  }, [selectedEnterpriseId])

  function handleSubmit(values, formikActions) {
    setSelectedEnterpriseId(values.enterpriseId)
    formikActions.setSubmitting(false)
  }

  const enterpriseListDropdown = !stateEnterprises
    ? []
    : stateEnterprises.map((entrprse) => ({
        label: entrprse.name,
        value: entrprse.id,
      }))

  const address = boldlessData?.address
    ? `${boldlessData?.address.street1} ${boldlessData?.address.street2}, ${boldlessData?.address.city}, ${boldlessData?.address.state} ${boldlessData?.address.postalCode}`
    : ''

  return (
    <View className="AdminBoldlessEnterpriseMembers">
      <Container align="left" size="small">
        <Heading level={2}>Boldless enterprise members</Heading>
        {selectedEnterpriseId && (
          <Formik
            initialValues={{ enterpriseId: selectedEnterpriseId }}
            validationSchema={Yup.object({
              enterpriseId: validations.REQUIRED,
            })}
            onSubmit={handleSubmit}>
            {(formikProps) => (
              <Form>
                <Field2
                  label="Enterprise"
                  entity="partner"
                  name="enterpriseId"
                  type="select"
                  options={enterpriseListDropdown}
                />
                <Button
                  id="fetchBoldlessEnterpriseMembers"
                  disabled={formikProps.isSubmitting}
                  onClick={formikProps.submitForm}>
                  Get Boldless Enterprise Members
                </Button>
              </Form>
            )}
          </Formik>
        )}
        <Divider />
        <Table
          head={[
            'Date of eligibility',
            'First name',
            'Last name',
            'Email',
            'Phone',
            'Address',
            'Gender',
            'Preferred Language',
            'Date of birth',
            'External ID',
          ]}>
          {boldlessData.map((member) => (
            <tr key={member.id}>
              <td>{member.eligibilityStartDate}</td>
              <td>{member.firstName}</td>
              <td>{member.lastName}</td>
              <td>{member.email}</td>
              <td>{member.phoneNumber}</td>
              <td>{address}</td>
              <td>{member.gender}</td>
              <td>{member.preferredLanguage}</td>
              <td>{member.dob}</td>
              <td>{member.externalId}</td>
            </tr>
          ))}
        </Table>
      </Container>
    </View>
  )
}
