import React from 'react'
import HeaderBack from 'components/HeaderBack'
import paths from 'constants/paths'

export default function SettingsHeaderBack() {
  return (
    <HeaderBack to={paths.SETTINGS} flush className="SettingsHeaderBack">
      Settings
    </HeaderBack>
  )
}
