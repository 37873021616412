import React from 'react'
import Container from 'components/Container'
import View from 'components/View'
import EnterpriseSubscription from 'features/enterprise/components/EnterpriseSubscription'
import SettingsHeaderBack from 'features/user/components/SettingsHeaderBack'
import Subscription from 'features/user/components/Subscription'
import useEventPageVisited from 'hooks/use-event-page-visited'

export default function SettingsSubscription() {
  useEventPageVisited('Settings subscription')

  const commonProps = {
    title: 'Plan',
  }

  return (
    <View className="SettingsSubscription">
      <SettingsHeaderBack />
      <Container flush size="medium">
        <Subscription {...commonProps} />
        <EnterpriseSubscription {...commonProps} />
      </Container>
    </View>
  )
}
