import classNames from 'classnames'
import { differenceInHours, format, isThisWeek, isToday, isTomorrow } from 'date-fns'
import _ from 'lodash'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import Container from 'components/Container'
import Head from 'components/Head'
import Header from 'components/Header'
import HeaderSubheader from 'components/HeaderSubheader'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import { useGlobalContext } from 'contexts/GlobalContext'
import SubscriptionUpgradeSection from 'features/user/components/SubscriptionUpgradeSection'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as date from 'libs/date'
import type { LessonStream } from 'libs/lesson'
//import * as liveLesson from 'libs/live-lesson'
import * as url from 'libs/url'
import * as userLib from 'libs/user'
import * as userApiRequest from 'libs/user-api-request'
import styles from './LiveLessons.module.scss'
import LiveLessonsComponent from './components/LiveLessons'

//import { ReactComponent as FlagSvg } from './images/flag.svg'

type Props = {
  lessonId?: string
}

export default function LiveLessons({ lessonId: propsLessonId }: Props) {
  useEventPageVisited('Live lessons')
  const globalContext = useGlobalContext()
  //const [hasLessonsSummit, setHasLessonsSummit] = React.useState(true)
  //const [isSummitFilterOn, setIsSummitFilterOn] = React.useState(false)
  //const [lessons, setLessons] = React.useState<LessonStream[]>([])
  const [lessonsDays, setLessonsDays] = React.useState<string[]>([])
  const [lessonsFiltered, setLessonsFiltered] = React.useState<LessonStream[]>([])
  const [lessonId, setLessonId] = React.useState(propsLessonId)

  React.useEffect(() => {
    const { lessonId: queryLessonId } = url.getQueryString()
    // we check for a query lessonId in the url in case this is a desktop fallback
    if (!lessonId && queryLessonId) setLessonId(queryLessonId)
  }, [lessonId])

  React.useEffect(() => {
    async function getLessons() {
      const response = await userApiRequest.getLiveLessons()

      if (_.isEmpty(response) || _.isEmpty(response.data)) return

      const lessonsUpcoming = _.orderBy(
        response.data.filter((item: LessonStream) => {
          const hoursUntilClass = differenceInHours(
            date.getDateFromTimestamp(item.scheduledStartTs),
            new Date()
          )
          return hoursUntilClass > -1
        })
      )

      //const { filter } = url.getQueryString()
      //const isSummit = filter === 'summit'
      const lessonsInitial = lessonsUpcoming

      //if (isSummit) {
      //  lessonsInitial = lessonsUpcoming.filter(
      //    (item) => item.lesson.purpose === liveLesson.PURPOSE_SUMMIT
      //  )
      //  setIsSummitFilterOn(true)
      //}

      //setHasLessonsSummit(
      //  _.some(lessonsUpcoming, (item) => item.lesson.purpose === liveLesson.PURPOSE_SUMMIT)
      //)
      //setLessons(lessonsInitial)
      setLessonsFiltered(lessonsInitial)
    }

    getLessons()
  }, [])

  React.useEffect(() => {
    setLessonsDays(
      Object.keys(
        _.groupBy(lessonsFiltered, (item: LessonStream) =>
          date.getYYYY_MM_DDFromTimestamp(item.scheduledStartTs)
        )
      ).slice(0, 6)
    )
  }, [lessonsFiltered])

  //function handleSummitFilterClick() {
  //  setLessonsFiltered(
  //    isSummitFilterOn
  //      ? lessons
  //      : lessons.filter((item) => item.lesson.purpose === liveLesson.PURPOSE_SUMMIT)
  //  )
  //  setIsSummitFilterOn(!isSummitFilterOn)
  //}

  return (
    <div className="LiveLessons">
      <Head
        bodyAttributes={{
          class: 'body---grey',
        }}
      />
      <Header
        subheader={
          <HeaderSubheader>
            <Row flush={_.isEmpty(lessonsDays)} size="small">
              <Text element="h1" size="xlarge" weight="medium" className={styles['header--title']}>
                Upcoming live classes
              </Text>
            </Row>
            {/*
            <MediaObject
              figure={
                hasLessonsSummit ? (
                  <Button
                    color={isSummitFilterOn ? 'purple' : 'white'}
                    icon={<FlagSvg />}
                    pill
                    size="xsmall"
                    onClick={handleSummitFilterClick}>
                    Summit
                  </Button>
                ) : undefined
              }
              figurePosition="right">
            */}
            {!_.isEmpty(lessonsDays) && (
              <MediaObject
                figure={
                  <Text casing="upper" weight="medium">
                    Filter by day
                  </Text>
                }
                center
                reset="mobile"
                className={styles.filters}>
                <Buttons>
                  {lessonsDays.map((day) => {
                    const dateParsed = date.getDateFromYYYY_MM_DD(day)
                    let dateFormatted = format(dateParsed, 'EEE d')

                    if (isToday(dateParsed)) {
                      dateFormatted = 'Today'
                    } else if (isTomorrow(dateParsed)) {
                      dateFormatted = 'Tomorrow'
                    } else if (isThisWeek(dateParsed)) {
                      dateFormatted = format(dateParsed, 'EEEE')
                    }

                    return (
                      <Button to={`#${day}`} color="white" key={day} pill size="xsmall">
                        {dateFormatted}
                      </Button>
                    )
                  })}
                </Buttons>
              </MediaObject>
            )}
            {/*
            </MediaObject>
            */}
          </HeaderSubheader>
        }
      />
      <View color="grey">
        <Container flush size="xxlarge">
          <div className={styles['lessons-and-overlay']}>
            {lessonsFiltered && (
              <LiveLessonsComponent
                data={lessonsFiltered}
                lessonId={lessonId}
                className={classNames(
                  !userLib.hasLiveLessons(globalContext.user) && styles.lessons
                )}
              />
            )}
            {!userLib.hasLiveLessons(globalContext.user) && (
              <div className={styles.overlay}>
                <Container flush size="medium">
                  <Box align="center" color="white">
                    <SubscriptionUpgradeSection />
                  </Box>
                </Container>
              </div>
            )}
          </div>
        </Container>
      </View>
    </div>
  )
}
