import { Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import Button from 'components/Button'
import Field2 from 'components/Field2'
import Heading from 'components/Heading'
import statusCodes from 'constants/status-codes'
import * as adminApiRequest from 'features/admin/libs/admin-api-request'
import EnterpriseReefOrcaFieldCode from 'features/enterprise/reef-orca/components/EnterpriseReefOrcaFieldCode'
import * as validations from 'libs/validations'

type Props = {
  onSuccess: () => void
  onError: () => void
}

export default function ConvertEnterpriseUser({ onSuccess, onError }: Props) {
  function handleSubmit(values, formikActions) {
    const { email, enterpriseInternalId, externalId } = values
    adminApiRequest
      .convertEnterpriseUser({
        email,
        enterpriseInternalId,
        externalId: externalId || `${enterpriseInternalId}_EXTERNAL_ID_${Math.random().toString()}`,
      })
      .then((response) => {
        if (response.statusCode === statusCodes.SUCCESS && onSuccess) {
          onSuccess()
          formikActions.resetForm()
        } else {
          onError()
        }
        formikActions.setSubmitting(false)
      })
  }

  const enterpriseList = [
    {
      label: 'OnePass',
      value: 'orca',
    },
    {
      label: 'Renew Active',
      value: 'reef',
    },
  ]

  return (
    <div>
      <Heading level={2}>
        Convert a user to Enterprise by email (Renew Active & One Pass only)
      </Heading>
      <Formik
        initialValues={{
          email: '',
          externalId: '',
          enterpriseInternalId: '',
        }}
        validationSchema={Yup.object({
          email: validations.REQUIRED,
          enterpriseInternalId: validations.REQUIRED,
          externalId: validations.REEF_ORCA_EXTERNAL_ID,
        })}
        onSubmit={handleSubmit}>
        {(formikProps) => (
          <Form>
            <Field2 label="Email" name="email" />
            <Field2
              type="select"
              label="Enterprise"
              name="enterpriseInternalId"
              options={enterpriseList}
            />
            <EnterpriseReefOrcaFieldCode name="externalId" label="Confirmation Code" />
            <Button
              id="convertEnterpriseUser"
              disabled={formikProps.isSubmitting}
              onClick={formikProps.submitForm}>
              Convert user
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
