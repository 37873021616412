import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import type { Component } from 'constants/types'
import * as programLib from 'libs/program'
import { useActiveProgramProgress, useProgram, useUserProgramBlocks } from 'libs/program-query'
import styles from './ProgramProgress.module.scss'

type Props = {
  color?: 'purple'
} & Component

export default function ProgramProgress({ className, color = 'purple' }: Props) {
  const { data: programData } = useProgram()
  const { data: programProgressData } = useActiveProgramProgress()
  const program = programData?.data
  const { data: userProgramBlocksResponse } = useUserProgramBlocks(program?.id, {
    dependencies: [programData],
  })
  const programProgress = programProgressData?.data
  const programBlocks = userProgramBlocksResponse?.data
  const activeBlock = _.find(programBlocks, (block) => block.status === 'active')

  if (program == null || programProgress == null) {
    return null
  }

  // TODO: use constants
  // handle static and dynamic case
  let content = null
  content =
    program.programType === programLib.TYPE_STATIC
      ? program.exerciseProgram?.exerciseBlocks.map((item, index) => (
          <Tippy key={index} content={`Section ${index + 1}`}>
            <div
              className={classNames(
                styles.marker,
                index === program.currentBlockIndex && styles['marker---current'],
                index > program.currentBlockIndex && styles['marker---future']
              )}
            />
          </Tippy>
        ))
      : programBlocks.map((item) => (
          <Tippy key={item.id} content={`Week ${item.indexInProgram + 1}`}>
            <div
              className={classNames(
                styles.marker,
                item.id === activeBlock?.id && styles['marker---current'],
                item.indexInProgram > activeBlock?.indexInProgram && styles['marker---future']
              )}
            />
          </Tippy>
        ))

  return (
    <div
      className={classNames(
        'ProgramProgress',
        styles.this,
        color && styles[`this---${color}`],
        className
      )}>
      <div className={styles.bar}>
        <div
          style={{
            transform: `translateX(-${100 - (programProgress.percentComplete ?? 0)}%)`,
          }}
          className={styles['bar--fill']}
        />
      </div>
      <div className={styles.markers}>{content}</div>
    </div>
  )
}
