import classNames from 'classnames'
import React from 'react'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import styles from './BoxSection.module.scss'

type Props = {
  children: React.ReactNode
  color?: 'grey' | 'white'
  element?: 'div'
  size?: 'large' | 'xmedium' | 'medium' | 'small'
} & Component

export default function BoxSection({
  children,
  className,
  color = 'white',
  element = 'div',
  size = 'medium',
  ...props
}: Props) {
  return (
    <Text
      {...props}
      element={element}
      className={classNames(
        'BoxSection',
        styles.this,
        styles[`this---${color}`],
        styles[`this---${size}`],
        className
      )}>
      {children}
    </Text>
  )
}
