import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Button from 'components/Button'
import Carousel from 'components/Carousel'
import Image from 'components/Image'
import Link from 'components/Link'
import Loader from 'components/Loader'
import Row from 'components/Row'
import Text from 'components/Text'
import * as events from 'constants/events'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import LessonTags from 'features/lesson/components/LessonTags'
import type { Lesson } from 'libs/lesson'
import { useUserLessonsFavorite } from 'libs/lessons-query'
import styles from './LessonsFavorite.module.scss'
import { ReactComponent as BookmarkSvg } from './images/bookmark.svg'
import Box from '../Box'

type Props = Component

export default function LessonsFavorite({ className }: Props) {
  const globalContext = useGlobalContext()
  const { data: lessonsFavoriteData, isLoading: isLessonsFavoriteLoading } =
    useUserLessonsFavorite()

  function handleViewAllClick() {
    globalContext.analytics?.trackEvent(events.DASHBOARD_LESSONS_FAVORITE_VIEW_ALL_CLICK)
  }

  const carouselOffset = 24

  if (!isLessonsFavoriteLoading && _.isEmpty(lessonsFavoriteData.data)) return null

  return (
    <Box
      title="My saved classes"
      titleColor="cyan"
      titleIcon={<BookmarkSvg />}
      headerButtonEntity="all classes"
      headerButtonTo={paths.LESSONS_FAVORITE}
      onHeaderButtonClick={handleViewAllClick}
      flush="x"
      className={classNames('LessonsFavorite', styles.this, className)}>
      {isLessonsFavoriteLoading ? (
        <Loader overlay={false} />
      ) : (
        <div className={styles.lessons}>
          <Carousel
            loop={false}
            navigationProps={{
              background: true,
            }}
            showNavigation
            slidesOffsetBefore={carouselOffset}
            slidesOffsetAfter={carouselOffset}
            slidesPerView="auto"
            spaceBetween={16}>
            {lessonsFavoriteData.data?.map((item: Lesson) => (
              <Link
                to={`${paths.LESSON}${item.id}#explore`}
                key={item.id}
                className={styles.lesson}>
                <Row size="xsmall">
                  <div className={styles['lesson--image-wrap']}>
                    <Image src={item.videoThumbnail} className={styles['lesson--image']} />
                    <div className={styles['lesson--image-overlay']}>
                      <LessonTags data={item} />
                    </div>
                  </div>
                </Row>
                <Row size="xxxsmall">
                  <Text element="h3" weight="medium" className={styles['lesson--title']}>
                    {item.videoTitle}
                  </Text>
                </Row>
                <Text size="small">
                  {item.instructorName}
                  {!_.isEmpty(item.classType) && (
                    <>
                      {' • '}
                      {item.classType.filter(Boolean).slice(0, 3).join(', ')}
                    </>
                  )}
                </Text>
              </Link>
            ))}
            <Link to={paths.EXPLORE} className={styles['lessons--view-all']}>
              <Button color="white" faux icon="arrow-right" level="text">
                View all
              </Button>
            </Link>
          </Carousel>
        </div>
      )}
    </Box>
  )
}
