import classNames from 'classnames'
import _ from 'lodash'
import pluralize from 'pluralize'
import React from 'react'
import Loader from 'components/Loader'
import MediaObject from 'components/MediaObject'
import Tag from 'components/Tag'
import Text from 'components/Text'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import SubscriptionPlanPrice from 'features/user/components/SubscriptionPlanPrice'
import * as subscriptions from 'libs/subscriptions'
import type { Plan } from 'libs/subscriptions'

type Props = {
  plan?: Plan
} & Component

export default function SubscriptionPlanWithPrice({ className, plan: propsPlan }: Props) {
  const globalContext = useGlobalContext()
  const [amount, setAmount] = React.useState(0)
  const [plan, setPlan] = React.useState()

  // TODO: globalContext.selectedPlan instead of propsPlan?
  React.useEffect(() => {
    const planLocal = !_.isEmpty(propsPlan) ? propsPlan : globalContext.user?.subscription?.plan
    setPlan(planLocal)
    if (!planLocal) return

    setAmount(planLocal.amount / 100)

    if (!propsPlan) {
      if (globalContext.user?.subscription?.discount?.coupon?.percent_off) {
        // TODO: this only accounts for percent off, should it account for amount off?
        setAmount(
          (planLocal.amount -
            (planLocal.amount * globalContext.user.subscription.discount.coupon.percent_off) /
              100) /
            100
        )
      }

      if (
        globalContext.user?.subscription?.metadata?.extends_free_trial &&
        globalContext.user?.subscription?.metadata?.duration_in_days === 365
      ) {
        setAmount(0)
      }
    }
  }, [globalContext.user, propsPlan])

  if (!plan) return <Loader overlay={false} />

  return (
    <MediaObject
      figure={
        <SubscriptionPlanPrice
          data={amount}
          append={
            subscriptions.isFreePlan(globalContext.user?.subscription?.plan)
              ? ''
              : `Billed every ${pluralize(
                  plan.interval,
                  plan.interval_count,
                  plan.interval_count > 1
                )}`
          }
        />
      }
      center
      figurePosition="right"
      reset="mobile"
      split
      className={classNames('SubscriptionPlanWithPrice', className)}>
      <MediaObject
        figure={
          !subscriptions.isFreePlan(plan) && (
            <Tag casing="capitalize" color="purple100" weight="medium">
              {subscriptions.getPlanName(plan)}
            </Tag>
          )
        }
        center
        figurePosition="right"
        inline
        size="xxsmall">
        <Text size="large" weight="semibold">
          {subscriptions.getPlanLevel2(plan)}
        </Text>
      </MediaObject>
    </MediaObject>
  )
}
