import classNames from 'classnames'
import React from 'react'
import { type ModalProps } from 'components/Modal'
import { useUserGoal } from 'libs/user-query'
import { ReactComponent as GoalSvg } from './images/goal.svg'
import ProgramGoalStreakModal from '../ProgramGoalStreakModal'
import ProgramGoalWeek from '../ProgramGoalWeek'

type Props = ModalProps

export default function ProgramGoalModal({ className, ...props }: Props) {
  const { data: userGoalData } = useUserGoal()

  return (
    <ProgramGoalStreakModal
      {...props}
      title={`You hit your ${userGoalData?.data?.goalExerciseFrequency}-day goal`}
      subtext="Congratulations on hitting your weekly activity goal. Each week is a chance to build your strength with Bold and you did it!"
      image={<GoalSvg aria-hidden />}
      className={classNames('ProgramGoalModal', className)}>
      <ProgramGoalWeek />
    </ProgramGoalStreakModal>
  )
}
