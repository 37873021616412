import React from 'react'
import Button from 'components/Button'
import * as notifications from 'libs/notifications'
import { requestMagicLink } from 'libs/user-api-request'

type Props = {
  lessonId: string
  onEmailSent: Function
}

export default function MagicLinkButton({ lessonId, onEmailSent }: Props) {
  const [isSending, setIsSending] = React.useState(false)

  function resetState() {
    setIsSending(false)
  }

  async function handleClick() {
    setIsSending(true)
    const result = await requestMagicLink(window.location.href, lessonId)
    resetState()
    if (result.statusCode === 200) {
      onEmailSent()
    } else {
      notifications.notifyError('Email was not sent, please try again')
    }
  }

  return (
    <Button
      color="yellow"
      loading={!!isSending}
      full
      className="MagicLinkButton"
      onClick={handleClick}>
      {isSending ? 'Sending email' : 'Send email'}
    </Button>
  )
}
