import classNames from 'classnames'
import { Link as GatsbyLink } from 'gatsby'
import _ from 'lodash'
import React from 'react'
import Box from 'components/Box'
import Button from 'components/Button'
import Heading2 from 'components/Heading2'
import Image from 'components/Image'
import LessonTrainer from 'components/LessonTrainer'
import Loader from 'components/Loader'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import View from 'components/View'
import * as events from 'constants/events'
import paths from 'constants/paths'
import type { Component } from 'constants/types'
import { useGlobalContext } from 'contexts/GlobalContext'
import ProgramProgress from 'features/program/components/ProgramProgress'
import { useLatestUserCheckinResponses } from 'libs/checkin-query'
import type { Lesson } from 'libs/lesson'
import * as programLib from 'libs/program'
import {
  useLessonsForProgramDynamic,
  useLessonsForProgramStatic,
  useProgram,
} from 'libs/program-query'
import { CHECKIN_RESPONSE_VALUES } from 'libs/checkins/checkin-constants'
import styles from './Program.module.scss'
import { ReactComponent as PlaySvg } from './images/play.svg'
import { ReactComponent as ProgramSvg } from './images/program.svg'
import DashboardBox from '../Box'

type Props = Component

export default function Program({ className }: Props) {
  const globalContext = useGlobalContext()
  const { data: latestUserCheckinResponsesData } = useLatestUserCheckinResponses()
  const { data: programData } = useProgram()
  const program = programData?.data
  // For now, need to support both static and dynamic programs in this component
  const { data: lessonsForProgramStaticData, isLoading: isLoadingStatic } =
    useLessonsForProgramStatic(program?.exerciseProgram?.id, {
      dependencies: [programData],
    })
  const { data: lessonsForProgramDynamicData, isLoading: isLoadingDynamic } =
    useLessonsForProgramDynamic(program?.id, {
      dependencies: [programData],
    })
  const lessonsForPrograms =
    program?.programType === programLib.TYPE_STATIC
      ? lessonsForProgramStaticData?.data
      : lessonsForProgramDynamicData?.data
  const [copyPosition, setCopyPosition] = React.useState('')
  const [copyImproving, setCopyImproving] = React.useState('')
  const [lesson, setLesson] = React.useState<Lesson | {}>({}) // first lesson of program to display thumbnail

  React.useEffect(() => {
    setLesson(programLib.getLessonCurrent(lessonsForPrograms))
  }, [lessonsForPrograms])

  React.useEffect(() => {
    const latestUserCheckinResponses = latestUserCheckinResponsesData?.data
    setCopyPosition(latestUserCheckinResponses?.preferred_exercise_position?.responseValue)

    if (
      latestUserCheckinResponses?.preferred_exercise_position?.responseValue ===
      CHECKIN_RESPONSE_VALUES.STANDING_AND_FLOOR
    ) {
      setCopyPosition('standing and floor')
    } else if (
      latestUserCheckinResponses?.preferred_exercise_position?.responseValue ===
      CHECKIN_RESPONSE_VALUES.STANDING_WITH_ASSISTANCE
    ) {
      setCopyPosition('standing')
    }

    if (latestUserCheckinResponses?.motivation_to_start_bold?.responseValue === 'balance') {
      setCopyImproving('balance. Members who completed this program reduced their fall risk by 46%')
    } else if (
      latestUserCheckinResponses?.motivation_to_start_bold?.responseValue === 'joint pain'
    ) {
      setCopyImproving(
        'joint pain. Members who complete this resistance exercise program reported reducing joint pain by up to 60%'
      )
    } else {
      setCopyImproving(
        'strength and mobility. We’ll guide you through a fun mix of class styles with cardio and strength focused moves'
      )
    }
  }, [latestUserCheckinResponsesData])

  function handleLessonButtonClick() {
    globalContext.analytics?.trackEvent(events.DASHBOARD_PROGRAM_LESSON_BUTTON_CLICK)
  }

  function handleSetupProgramButtonClick() {
    globalContext.analytics?.trackEvent(events.DASHBOARD_PROGRAM_SETUP_PROGRAM_BUTTON_CLICK)
  }

  function handleProgramButtonClick() {
    globalContext.analytics?.trackEvent(events.DASHBOARD_PROGRAM_BUTTON_CLICK)
  }

  const componentClassNames = classNames('Program', styles.this, className)

  if (isLoadingStatic || isLoadingDynamic) {
    return (
      <View color="grey">
        <Loader overlay={false} />
      </View>
    )
  }

  if (_.isEmpty(lessonsForPrograms)) {
    return (
      <Box className={classNames(componentClassNames, styles['this---empty'])}>
        <Text color="white" element="div">
          <Heading2 level={3} levelStyle={5}>
            Setup your program
          </Heading2>
          <Text element="p">
            Your customized {copyPosition} program will focus on improving {copyImproving}.
          </Text>
        </Text>
        <Button
          to={paths.ONBOARDING_PROGRAM_BUILD}
          color="purple"
          className={styles.button}
          onClick={handleSetupProgramButtonClick}>
          Get started
        </Button>
      </Box>
    )
  }

  if (_.isEmpty(lesson)) {
    return null
  }

  return (
    <DashboardBox
      title="My program"
      titleColor="purple"
      titleIcon={<ProgramSvg />}
      headerButtonEntity="program"
      headerButtonTo={paths.PROGRAM}
      onHeaderButtonClick={handleProgramButtonClick}
      flush
      footer={
        <MediaObject
          figure={<Text weight="semibold">My progress</Text>}
          center
          size="small"
          className={styles.footer}>
          <ProgramProgress />
        </MediaObject>
      }
      className={componentClassNames}>
      <GatsbyLink
        to={paths.PROGRAM}
        className={styles['image-and-overlay']}
        onClick={handleLessonButtonClick}>
        <Image src={lesson.videoThumbnail} full className={styles.image} />
        <Text color="white" element="div" className={styles['image-overlay']}>
          <Row size="xsmall">
            <Text element="h3" size="large" weight="semibold">
              {lesson.videoTitle}
            </Text>
          </Row>
          <LessonTrainer data={lesson} />
          <PlaySvg className={styles['play-button']} />
        </Text>
      </GatsbyLink>
    </DashboardBox>
  )
}
