import { navigate } from 'gatsby'
import React from 'react'
import ProgramBuild from 'components/ProgramBuild'
import paths from 'constants/paths'
import useEventPageVisited from 'hooks/use-event-page-visited'
import { useUserProfile } from 'libs/user-query'

export default function OnboardingProgramBuild() {
  useEventPageVisited('Onboarding program build')
  const { data: latestUserProfileData } = useUserProfile()
  const latestUserProfile = latestUserProfileData?.data
  const programFocus = latestUserProfile?.latestProfile?.programFocus

  const pathToNavigateTo =
    programFocus === 'pelvic_health' ? paths.PROGRAM : paths.ONBOARDING_PROGRAM_INTRODUCTION
  return (
    <ProgramBuild
      className="OnboardingProgramBuild"
      onComplete={() => navigate(pathToNavigateTo, { replace: true })}
    />
  )
}
