import React from 'react'
import * as rdd from 'react-device-detect'
import Modal from 'components/Modal'
import storage from 'constants/storage'
import { useGlobalContext } from 'contexts/GlobalContext'
import AppDownloadModalBox from 'features/app/components/AppDownloadModalBox'
import * as cookie from 'libs/cookie'
import * as user from 'libs/user'

export default function AppDownloadModal() {
  const globalContext = useGlobalContext()
  const [isOpen, setIsOpen] = React.useState(false)

  React.useEffect(() => {
    const isSpecialMobile =
      user.isEnterpriseUser(globalContext.user) &&
      user.hasHomeSpecial() &&
      rdd.isMobile &&
      rdd.isIOS

    async function track() {
      if (
        !isSpecialMobile ||
        cookie.getCookie(storage.MOBILE_APP_DOWNLOAD_MODAL_KEY) === storage.MODAL_DISMISSED
      ) {
        return
      }

      await globalContext.analytics?.trackEvent('App download modal shown')
      setIsOpen(true)
    }

    track()
  }, [globalContext.user, globalContext.analytics])

  async function handleRequestClose() {
    cookie.createCookie(storage.MOBILE_APP_DOWNLOAD_MODAL_KEY, storage.MODAL_DISMISSED)
    setIsOpen(false)

    await globalContext.analytics?.trackEvent('App download modal dismissed')
  }

  return (
    <Modal
      isOpen={isOpen}
      size="small"
      className="AppDownloadModal"
      onRequestClose={handleRequestClose}>
      <AppDownloadModalBox onRequestClose={handleRequestClose} />
    </Modal>
  )
}
