import qs from 'qs'
import * as api from 'libs/api'

export async function getUserLessonsForExerciseBlock(
  exerciseBlockId: string,
  verifyProgramBlock = true
) {
  try {
    return await api.get(
      `user/exerciseBlock/${exerciseBlockId}/lessons?${qs.stringify(verifyProgramBlock)}`
    )
  } catch (error) {
    return { error }
  }
}
