import classNames from 'classnames'
import React from 'react'
import Row, { type RowProps } from 'components/Row'
import Text from 'components/Text'

type Props = Omit<RowProps, 'children'>

export default function FormFieldsRequired({ className, ...props }: Props) {
  return (
    <Row {...props} size="smallx" className={classNames('FormFieldsRequired', className)}>
      <Text element="div">
        <Text color="red">*</Text>
        is required
      </Text>
    </Row>
  )
}
