/* eslint-disable no-else-return */
import React from 'react'
import Button from 'components/Button'

function LowerBodyMovementDisplay({
  currentProgramName,
  userProfile,
  bestMatchPrograms,
  onSelect,
}) {
  const getColor = (number: number, isUser: boolean) => {
    const value = number
    if (isUser) {
      if (value === 0) {
        // moderate is green
        return 'rgb(0, 255, 0)'
      } else if (value >= 1 && value < 2) {
        // easy is yellow
        return 'rgb(255, 255, 0)'
      } else if (value >= 10) {
        // unable is dark red
        return 'rgb(139, 0, 0)'
      } else if (value >= 2) {
        // difficult is light red
        return 'rgb(255, 102, 102)'
      }
    } else {
      if (value > 0) return 'rgb(0, 255, 0)' // green if contains move at all
    }
  }

  if (!userProfile?.lowerBodyMovementProfile || !bestMatchPrograms) {
    return null
  }

  return (
    <table>
      <thead>
        <tr>
          <th />
          {userProfile.lowerBodyMovementProfile.map((value, index) => (
            <th key={index} style={{ padding: '0 2.5px' }}>
              {index + 1}
            </th>
          ))}
          <th style={{ padding: '0 2.5px' }} />
          <th style={{ padding: '0 4px' }}>Avg Intensity</th>
          <th style={{ padding: '0 4px' }}>Avg Class Duration</th>
          <th style={{ padding: '0 4px' }}>Match Distance</th>
          <th>Select</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ fontWeight: 'bold' }}>User Profile:</td>
          {userProfile.lowerBodyMovementProfile.map((value, index) => (
            <td
              key={index}
              style={{
                padding: '0 2.5px',
                backgroundColor: getColor(value, true),
                fontWeight: 'semibold',
              }}>
              {Number.parseFloat(value).toFixed(1)}
            </td>
          ))}
        </tr>
        <tr>
          <td style={{ height: '24px' }} />
        </tr>
        <tr>
          <td style={{ fontWeight: 'bold' }}>Programs:</td>
        </tr>
        <tr />
        {bestMatchPrograms.map((program) => (
          <tr
            style={
              program.title === currentProgramName ? { border: '3px solid rgb(0, 0, 0)' } : {}
            }>
            <td>{program.title}</td>
            {program.firstBlockLowerBodyMovementProfile.map((value, index) => (
              <td
                key={index}
                style={{
                  padding: '0 2.5px',
                  backgroundColor: getColor(value, false),
                }}>
                {(value / program.firstBlockEffectiveDuration).toFixed(1)}
              </td>
            ))}
            <td style={{ padding: '0 4px' }} />
            <td>{Number.parseFloat(program.firstBlockAverageIntensity).toFixed(1)}</td>
            <td>{Number.parseFloat(program.firstBlockAverageLessonDuration).toFixed(1)}</td>
            <td>{Number.parseFloat(program.distanceScore).toFixed(2)}</td>
            <td>
              <Button onClick={() => onSelect(program)}>Select</Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default LowerBodyMovementDisplay
