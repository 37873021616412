import * as environment from 'libs/environment'
import * as logging from 'libs/logging'

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    dataLayer: any
  }
}

function logToConsole({ data, message }: { data?: string | {}; message: string }) {
  logging.toConsole(
    {
      message: `🚂 EVENT TRACKING - ${message}`,
      data,
    },
    '#4285f4'
  )
}

// 1. Create trigger in Google Tag Manager
// 2. Create tag in Google Tag Manager
// 3. Call `window.dataLayer.push` with custom event name
// 4. $$$?

function google(event: string) {
  logToConsole({
    message: `GOOGLE - ${event}`,
  })

  if (!environment.isProduction || window.dataLayer === undefined) return

  window.dataLayer.push({ event })
}

export function onboardingComplete() {
  google('onboarding-complete')
}

export function signUp() {
  google('sign-up')
}
