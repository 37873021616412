import { format } from 'date-fns'
import pluralize from 'pluralize'
import React from 'react'
import Box from 'components/Box'
import MediaObject from 'components/MediaObject'
import Row from 'components/Row'
import Text from 'components/Text'
import statusCodes from 'constants/status-codes'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as date from 'libs/date'
import * as userApiRequest from 'libs/user-api-request'
import { useUserStreakLongest } from 'libs/user-query'
import styles from './Stats.module.scss'
import { ReactComponent as GoalStreakLongestSvg } from './images/goal-streak---longest.svg'
import { ReactComponent as GoalStreakSvg } from './images/goal-streak.svg'
import { ReactComponent as GraphSvg } from './images/graph.svg'

type Data = {
  allTimeClassesWatched: number
  streak: number
}

type Props = {
  onEmpty: () => void
}

export default function Stats({ onEmpty }: Props) {
  const globalContext = useGlobalContext()
  const { data: userStreakLongestData } = useUserStreakLongest()
  const streakLongest = userStreakLongestData?.data?.longestStreak
  const [data, setData] = React.useState<Data | {}>({})

  React.useEffect(() => {
    async function getData() {
      const response = await userApiRequest.getActivityStatsForUser()

      if (response.code !== statusCodes.SUCCESS) {
        onEmpty()
        return
      }

      setData(response.data)
      const { allTimeClassesWatched, streak } = response.data

      if (!allTimeClassesWatched && !streak) {
        onEmpty()
      }
    }
    getData()
  }, [onEmpty])

  const { allTimeClassesWatched, streak } = data
  if (!allTimeClassesWatched) {
    return null
  }

  const signUpDateParsed = date.getDateFromTimestamp(globalContext.user.signUpDate)
  const stats = [
    {
      title: `${allTimeClassesWatched} total ${pluralize('class', allTimeClassesWatched)} completed`,
      copy: `since you joined in ${format(signUpDateParsed, 'MMMM yyyy')} `,
      icon: GraphSvg,
      hide: !allTimeClassesWatched,
    },
    {
      title: 'Current streak',
      copy: pluralize('week', streak || 1, true),
      icon: GoalStreakSvg,
      hide: !streak,
    },
    {
      title: 'Longest streak',
      copy: pluralize('week', streakLongest, true),
      icon: GoalStreakLongestSvg,
      hide: !streakLongest,
    },
  ]

  return (
    <div className="Stats">
      <div className={styles.grid}>
        {stats
          .filter((item) => !item.hide)
          .map((item) => (
            <Box color="white" key={item.title} className={styles.box}>
              <MediaObject figure={<item.icon />} center size="small" reset="tablet">
                <Row flush={!item.copy} size="xxsmall">
                  <Text element="h3" size="large" weight="semibold">
                    {item.title}
                  </Text>
                </Row>
                <span>{item.copy}</span>
              </MediaObject>
            </Box>
          ))}
      </div>
    </div>
  )
}
