import classNames from 'classnames'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import Button from 'components/Button'
import type { Component } from 'constants/types'
import * as notifications from 'libs/notifications'

type Props = {
  children: React.ReactNode
  copy: string
  notification?: string
  onCopy?: () => void
} & Component

export default function ButtonCopyToClipboard({
  children = 'Copy',
  className,
  copy,
  notification,
  onCopy,
  ...props
}: Props) {
  const [text, setText] = React.useState('')

  function handleCopy() {
    setText('Copied!')

    setTimeout(() => {
      setText('')
    }, 4000)

    if (notification) notifications.notifySuccess(notification)

    if (onCopy) onCopy()
  }

  return (
    <CopyToClipboard text={copy} onCopy={handleCopy}>
      <Button {...props} className={classNames('ButtonCopyToClipboard', className)}>
        {text || children}
      </Button>
    </CopyToClipboard>
  )
}
