import classNames from 'classnames'
import React from 'react'
import { Elements } from 'react-stripe-elements'
import type { Component } from 'constants/types'
import StripeCardForm from './components/StripeCardForm'

type Props = Component

export default function SubscriptionPaymentMethodForm({ className, ...props }: Props) {
  return (
    <div className={classNames('SubscriptionPaymentMethodForm', className)}>
      <Elements
        fonts={[
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Inter:400',
          },
        ]}>
        <StripeCardForm {...props} />
      </Elements>
    </div>
  )
}
