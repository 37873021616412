import { navigate } from 'gatsby'
import React from 'react'
import Button from 'components/Button'
import Buttons from 'components/Buttons'
import ButtonsFloating from 'components/ButtonsFloating'
import ButtonWithModal from 'components/ButtonWithModal'
import Copy from 'components/Copy'
import Image from 'components/Image'
import Link from 'components/Link'
import ModalBox from 'components/ModalBox'
import Row from 'components/Row'
import Text from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import paths from 'constants/paths'
import urls from 'constants/urls'
import { useGlobalContext } from 'contexts/GlobalContext'
import OnboardingView from 'features/onboarding/components/OnboardingView'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as subscriptions from 'libs/subscriptions'
import ArrowUpRightSvg from './images/arrow---up-right.svg'

export default function OnboardingEnterpriseIneligibleCircle() {
  useEventPageVisited('Special: Nay page')
  const globalContext = useGlobalContext()
  const [showModal, setShowModal] = React.useState(false)

  function buildProgram() {
    navigate(paths.ONBOARDING_PROGRAM_BUILD)
  }

  async function startProgram() {
    await globalContext.analytics?.trackEvent('Special: Nay page: I want to try bold free click')
    buildProgram()
  }

  async function handleLearnMore() {
    await globalContext.analytics?.trackEvent('Special: Nay page: Learn More Click')
    setShowModal(true)
  }

  function closeModal() {
    setShowModal(false)
  }

  async function handleClose() {
    await globalContext.analytics?.trackEvent('Special: Nay page: Modal close')
    closeModal()
  }

  async function handleConfirmCancel() {
    await globalContext.analytics?.trackEvent('Special: Nay page: Modal no Stay here')
    closeModal()
  }

  async function handleContinueToCircle() {
    await globalContext.analytics?.trackEvent('Special: Nay page: Modal continue')
    closeModal()
    buildProgram()
  }

  async function handlePhoneClick() {
    await globalContext.analytics?.trackEvent('Special: Nay page: Circle phone number clicked')
  }

  return (
    <>
      <Row>
        <OnboardingView
          small="We couldn’t verify your eligibility, but you have options"
          title="You can still get access to Bold at no cost through your health plan!">
          <Text element="p">
            Get your personalized program and access to hundreds of live and on-demand classes by
            switching to a participating Medicare Advantage plan by December 7.
          </Text>
          <ButtonsFloating>
            <Buttons axis="y">
              <ButtonWithModal
                modal={
                  <ModalBox title="You are leaving Bold’s website" textAlign="left">
                    <Row>
                      <Copy align="left">
                        <Text element="p">
                          You are leaving <Text weight="medium">agebold.com</Text> to visit Circle
                          at the web address <Text weight="medium">circleengage.ai</Text>
                          .
                          <br />
                          You can also call Circle to speak to a broker at{' '}
                          <Link to="1-800-986-0391" color="purple" onClick={handlePhoneClick}>
                            <Text weight="medium">1-(800) 986-0391</Text>
                          </Link>
                          .
                        </Text>
                        <Text element="p" flush size="small">
                          Please note that Circle is not owned, operated, or controlled by Age Bold
                          Inc. or its affiliates. By visiting Circle‘s website, you will be subject
                          to their terms of use and privacy policy.
                        </Text>
                      </Copy>
                    </Row>
                    <Buttons align="center" equal>
                      <Button color="white" onClick={handleConfirmCancel}>
                        No, stay here
                      </Button>
                      <Button to={urls.CIRCLE} onClick={handleContinueToCircle}>
                        <TextWithIcon
                          icon={<Image src={ArrowUpRightSvg} />}
                          iconPosition="right"
                          inline>
                          Yes, continue
                        </TextWithIcon>
                      </Button>
                    </Buttons>
                  </ModalBox>
                }
                showModal={showModal}
                onClick={handleLearnMore}
                onClose={handleClose}>
                Learn more
              </ButtonWithModal>
              <Button color="white" full onClick={startProgram}>
                I want to try Bold for {subscriptions.TRIAL_DAYS} days, free
              </Button>
            </Buttons>
          </ButtonsFloating>
        </OnboardingView>
      </Row>
      <Text color="grey" element="p" flush size="xsmall">
        By clicking “learn more” you will connect to Circle, a non-government website. Age Bold,
        Inc. and Circle are not endorsed by or affiliated with Medicare or any government program.
        Circle will assist you in connecting you to a licensed agent in your area who can review
        plans that may be available to you. The licensed agents do not offer every available plan in
        your area. When you speak with the licensed agent, they will tell you the number of
        organizations and products they represent in your area. Please contact Medicare.gov or
        1-800-MEDICARE (TTY users should call 1-877-486-2048) 24 hours a day/7 days a week or your
        local State Health Insurance Program to get information on all of your options.
      </Text>
    </>
  )
}
