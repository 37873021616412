import { useLocation } from '@reach/router'
import classNames from 'classnames'
import { navigate } from 'gatsby'
import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'
import type { Component } from 'constants/types'
import * as navigation from 'libs/navigation'
import styles from './HeaderBack.module.scss'

type Props = {
  children?: React.ReactNode
  flush?: boolean
  onClick?: () => void
  to?: string
  toFallback?: string
} & Component

export default function HeaderBack({
  children = 'Back',
  className,
  flush,
  onClick,
  to,
  toFallback,
}: Props) {
  const location = useLocation()

  function handleClick() {
    if (onClick) onClick()

    const destination = to ?? location?.state?.from ?? toFallback
    if (destination) {
      navigate(destination, { state: location?.state })
    } else {
      navigation
        .back
        //location?.state
        ()
    }
  }

  return (
    <header
      className={classNames('HeaderBack', styles.this, flush && styles['this---flush'], className)}>
      <Container flush={flush} full>
        <nav className={styles.nav}>
          <Button color="white" icon="arrow-left" onClick={handleClick}>
            {children}
          </Button>
        </nav>
      </Container>
    </header>
  )
}
