import classNames from 'classnames'
import { addDays, endOfWeek, getDate, isBefore, isSameDay, isToday, startOfWeek } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import _ from 'lodash'
import React from 'react'
import Media from 'react-media'
import Row from 'components/Row'
import Text from 'components/Text'
import statusCodes from 'constants/status-codes'
import type { Component } from 'constants/types'
import * as date from 'libs/date'
import * as notifications from 'libs/notifications'
import * as userApiRequest from 'libs/user-api-request'
import constants from 'styles/constants.module.scss'
import styles from './Calendar.module.scss'

type Props = Component

export default function Calendar({ className }: Props) {
  const [data, setData] = React.useState({})

  const getData = React.useCallback(async () => {
    const response = await userApiRequest.getActivityStatsForUser()

    if (response.code === statusCodes.SUCCESS) {
      setData(response.data)
    } else {
      notifications.notifyError('Sorry, something went wrong displaying your activity calendar')
    }
  }, [])

  React.useEffect(() => {
    getData()
  }, [getData])

  if (_.isNil(data)) return

  // Convert every date to user's timezone
  const timezone = date.TIMEZONE
  const today = utcToZonedTime(new Date(), timezone)
  const firstOfMonth = utcToZonedTime(new Date(today.getFullYear(), today.getMonth(), 1), timezone)
  const lastDayOfMonth = utcToZonedTime(
    new Date(today.getFullYear(), today.getMonth() + 1, 0),
    timezone
  )
  const dateEnd = endOfWeek(lastDayOfMonth, { weekStartsOn: 0 })

  const dayStatusArray = []
  const activityDates = data.recentUserStats

  let currentCalendarDate = startOfWeek(firstOfMonth, { weekStartsOn: 0 })
  while (isBefore(currentCalendarDate, dateEnd)) {
    const currentDate = utcToZonedTime(new Date(currentCalendarDate), timezone)
    const dateObject = {
      date: getDate(currentCalendarDate),
      class: styles.day,
      fullDate: currentCalendarDate,
      isActive: false,
    }

    // gray out the background of the previous month
    if (isBefore(currentCalendarDate, firstOfMonth)) {
      dateObject.class = classNames(styles.day, styles['day---past'])
    }

    // Check for activity on the current date
    const hasActivityForDate = activityDates?.find((activeDay) =>
      isSameDay(utcToZonedTime(new Date(activeDay?.date), timezone), currentDate)
    )

    if (hasActivityForDate) {
      dateObject.isActive = true
      dateObject.class = classNames(styles.day, styles['day---active'])
    }

    // now if the date is in the active days it's "active"
    dayStatusArray.push(dateObject)
    currentCalendarDate = addDays(currentCalendarDate, 1)
  }

  const weeks = [
    dayStatusArray.slice(0, 7),
    dayStatusArray.slice(7, 14),
    dayStatusArray.slice(14, 21),
    dayStatusArray.slice(21, 28),
    dayStatusArray.slice(28, 35),
    dayStatusArray.slice(35, 42),
  ]

  return (
    <Text align="center" element="div" className={classNames('Calendar', styles.this, className)}>
      <Media query={{ minWidth: constants.GT_TABLET }}>
        <Row size="small">
          <Text element="h3" size="xxlarge" weight="medium" className={styles.title}>
            {date.MONTHS[today.getMonth()]}
          </Text>
        </Row>
      </Media>
      <div className={styles['table-wrap']}>
        <table className={styles.table}>
          <thead>
            <tr>
              {[
                ['Sunday', 'Su'],
                ['Monday', 'M'],
                ['Tuesday', 'Tu'],
                ['Wednesday', 'W'],
                ['Thursday', 'Th'],
                ['Friday', 'F'],
                ['Saturday', 'Sa'],
              ].map((item) => (
                <Text element="th" key={item[0]}>
                  <Media query={{ minWidth: constants.TABLET }}>
                    <>{item[0]}</>
                  </Media>
                  <Media query={{ maxWidth: constants.LT_TABLET }}>
                    <>{item[1]}</>
                  </Media>
                </Text>
              ))}
            </tr>
          </thead>
          <tbody>
            {weeks
              .filter((item) => !_.isEmpty(item))
              .map((item, index) => (
                <tr key={index} className={styles.days}>
                  {item.map((dateObject) => (
                    <Text
                      color={
                        isToday(utcToZonedTime(new Date(dateObject.fullDate), timezone))
                          ? 'purple'
                          : undefined
                      }
                      element="td"
                      key={dateObject.date}
                      weight={
                        isToday(utcToZonedTime(new Date(dateObject.fullDate), timezone))
                          ? 'medium'
                          : undefined
                      }
                      className={classNames(
                        dateObject.class,
                        isToday(utcToZonedTime(new Date(dateObject.fullDate), timezone)) &&
                          styles['day---today']
                      )}>
                      <span className={styles['day--number']}>{dateObject.date}</span>
                    </Text>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Text>
  )
}
