import classNames from 'classnames'
import { Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import Button, { type ButtonProps } from 'components/Button'
import FieldEmail from 'components/FieldEmail'
import MediaObject from 'components/MediaObject'
import type { Component } from 'constants/types'
import * as validations from 'libs/validations'

export type Props = {
  buttonProps?: ButtonProps | {}
  fieldProps?: {}
  inline: boolean
  onSubmit: () => void
} & Component

export default function EmailForm({
  buttonProps = {},
  className,
  fieldProps = {},
  inline,
  onSubmit,
}: Props) {
  function handleSubmit(values, formikActions) {
    onSubmit(values, formikActions)
  }

  const field = <FieldEmail {...fieldProps} labelHidden />

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validateOnBlur={false}
      validationSchema={Yup.object({
        email: validations.EMAIL,
      })}
      className={classNames('EmailForm', className)}
      onSubmit={handleSubmit}>
      {(formikProps) => {
        const button = (
          <Button
            {...buttonProps}
            color="purple"
            disabled={formikProps.isSubmitting}
            full
            onClick={formikProps.submitForm}
          />
        )

        return (
          <Form>
            {inline ? (
              <MediaObject figure={button} figurePosition="right" reset="mobile" size="small">
                {field}
              </MediaObject>
            ) : (
              <>
                {field}
                {button}
              </>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}
