import React from 'react'
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
} from 'react-stripe-elements'
import Field from 'components/Field'
import Fields from 'components/Fields'
import Input from 'components/Input'
import Label from 'components/Label'
import * as events from 'constants/events'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as notifications from 'libs/notifications'
import constants from 'styles/constants.module.scss'
import './StripeCardForm.scss'

type StripeInputValue = {
  complete: boolean
}

type Props = {
  id: string
  onError: () => void
  // eslint-disable-next-line no-unused-vars
  onSubmit: () => void
  // TODO
  // eslint-disable-next-line no-unused-vars
  onSuccess: (token: {}) => void
  // TODO
  // eslint-disable-next-line no-unused-vars
  onValidityChange: (isValid: boolean) => void
  // TODO: types from injectStripe
  elements: any
  stripe: any
}

function StripeCardForm({
  elements,
  onError,
  onSubmit = () => {},
  onSuccess,
  onValidityChange,
  stripe,
  ...props
}: Props) {
  const globalContext = useGlobalContext()
  const [isCardCvcValid, setIsCardCvcValid] = React.useState(false)
  const [isCardExpirationDateValid, setIsCardExpirationDateValid] = React.useState(false)
  const [isCardNumberValid, setIsCardNumberValid] = React.useState(false)
  const [name, setName] = React.useState(
    `${globalContext.user?.firstName} ${globalContext.user?.lastName}`
  )
  const [isNameValid, setIsNameValid] = React.useState(!!name)

  React.useEffect(() => {
    // prevent firing on mount
    if (!(isCardCvcValid && isCardExpirationDateValid && isCardNumberValid)) return

    onValidityChange(
      isCardCvcValid && isCardExpirationDateValid && isCardNumberValid && isNameValid
    )
  }, [isCardCvcValid, isCardExpirationDateValid, isCardNumberValid, isNameValid, onValidityChange])

  React.useEffect(() => {
    setIsNameValid(!!name)
  }, [name])

  function handleCardCvcChange(value: StripeInputValue) {
    setIsCardCvcValid(value.complete)
  }

  function handleCardExpirationDateChange(value: StripeInputValue) {
    setIsCardExpirationDateValid(value.complete)
  }

  function handleCardNumberChange(value: StripeInputValue) {
    setIsCardNumberValid(value.complete)
  }

  function handleNameChange(event) {
    setName(event.target.value)
  }

  async function submit() {
    const response = await stripe.createPaymentMethod('card', {
      billing_details: {
        email: globalContext.user.email,
        name,
      },
    })

    if (response.error) {
      globalContext.analytics?.trackEvent(events.STRIPE_CARD_FORM_SUBMIT_FAILURE)
      console.error(response.error)
      notifications.notifyError()
      onError()
      return
    }

    if (response.paymentMethod) {
      onSuccess(response.paymentMethod)
    }
  }

  function handleSubmit(event) {
    event.preventDefault()

    onSubmit()
    submit()
  }

  const stripeInputProps = {
    // https://stripe.com/docs/js/appendix/style
    style: {
      base: {
        fontFamily:
          'Inter, -apple-system, BlinkMacSystemFont, segoe ui, roboto, helvetica neue, arial, sans-serif',
        fontSize: '16px',
        fontWeight: '400',
      },
      empty: {
        'color': constants.INPUT_PLACEHOLDER,
        '::placeholder': {
          color: constants.INPUT_PLACEHOLDER,
        },
      },
    },
  }

  return (
    <form {...props} className="StripeCardForm" onSubmit={handleSubmit}>
      <Field>
        <Label>Name on Card</Label>
        <Input value={name} onChange={handleNameChange} />
      </Field>
      <Field>
        <Label>Card Number</Label>
        <CardNumberElement
          {...stripeInputProps}
          placeholder="xxxx xxxx xxxx 1234"
          onChange={handleCardNumberChange}
        />
      </Field>
      <Fields>
        <Field>
          <Label>Expiration Date</Label>
          <CardExpiryElement
            {...stripeInputProps}
            placeholder="MM/YY"
            onChange={handleCardExpirationDateChange}
          />
        </Field>
        <Field>
          <Label>CVC</Label>
          <CardCVCElement {...stripeInputProps} onChange={handleCardCvcChange} />
        </Field>
      </Fields>
    </form>
  )
}

export default injectStripe(StripeCardForm)
