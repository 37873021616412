import classNames from 'classnames'
import { useField } from 'formik'
import React from 'react'
import Field2 from 'components/Field2'
import Row from 'components/Row'
import type { Component } from 'constants/types'

type Props = {
  label: string
  labelNote?: any
  name: string
  //} & Field2Props
} & Component

export default function EnterpriseReefOrcaFieldCode({
  className,
  label = 'Renew Active or One Pass Code',
  labelNote,
  name = 'externalId',
  ...props
}: Props) {
  // eslint-disable-next-line no-unused-vars
  const [_field, _meta, helpers] = useField(name)

  function handleChange(event) {
    helpers.setValue(event.currentTarget.value.replaceAll('-', ''))
  }

  return (
    <Field2
      label={
        <>
          <Row size="xxxsmall">{label}</Row>
          {labelNote}
        </>
      }
      {...props}
      // stripping dashes so can be longer than actual limit
      // handled instead in form validation
      //maxLength={10}
      name={name}
      className={classNames('EnterpriseReefOrcaFieldCode', className)}
      onChange={handleChange}
    />
  )
}
